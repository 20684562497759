<template>
  <div>
    <v-container v-if="isResponsive" class="v-container-fluid" fluid>
      <v-row>
        <v-col>
          <!-- content product name text  -->
          <div>
            <p class="text-title text-center mon-bold mb-4">
              {{ aArticle.sProductName }}
            </p>
          </div>
          <!-- content image  -->
          <div>
            <div v-if="lengthImages > 0">
              <div class="content-resize-image">
                <div class="content-image">
                  <img :src="imageSelected" />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="content-image-empty">
                <img src="@/assets/images/placeholder-image.jpeg" alt="" />
              </div>
            </div>
          </div>
          <!-- content scrolled images and selected images -->
          <div class="scrolled-lateral-images" v-if="lengthImages > 0">
            <div
              v-for="(item, index) in aArticle.aImages"
              :key="index"
              @click="selectImage(item)"
              class="mr-5"
            >
              <img
                width="70px"
                height="70px"
                :src="item.oImages.md"
                style="border-radius: 23px;"
              />
            </div>
          </div>
          <!-- content location information  -->
          <p class="text-location mon-bold">
            <v-icon size="18px" color="#9AA6B1"> mdi-map-marker </v-icon>
            {{ aArticle.sLocationWarehouse }}
          </p>
          <!-- content vendor information  -->
          <div>
            <div class="display-flex align-items-center mb-4">
              <div>
                <img
                  v-if="lengthLogo > 0"
                  width="70px"
                  height="70px"
                  style="border-radius: 10px;object-fit: contain;background-color: white;"
                  :src="imageProvider"
                  alt=""
                />
                <img
                  v-else
                  width="70px"
                  height="70px"
                  style="border-radius: 10px;object-fit: contain;background-color: #F4F4F4;"
                  src="@/assets/images/placeholder-image.jpeg"
                  alt=""
                />
              </div>
              <div v-if="existProvider !== null" class="ml-5">
                <p class="text-name-warehouse mon-bold">
                  {{ aArticle.sVendorEnterpriseName }}
                </p>
                <v-btn
                  @click="redirecVendorDetail(aArticle.sVendorEnterpriseId)"
                  class="link-button-warehouse mon-medium"
                  text
                >
                  {{ texts.viewArticle.textLinkButtonProductsProvider }}
                  <v-icon class="ml-1" color="#0971FB" size="18px">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div
              v-if="existProvider !== null"
              class="display-flex align-items-center mb-4"
            >
              <v-rating
                :value="aArticle.dRating"
                length="5"
                color="#FFC92A"
                background-color="#C6C6C8"
                dense
                small
                readonly
              ></v-rating>
              <p
                :style="aArticle.dRating == 0 ? { color: '#C6C6C8' } : {}"
                class="text-calif-num mon-bold ml-1 mb-n1"
              >
                {{ aArticle.dRating > 5 ? 5 : aArticle.dRating }}
              </p>
            </div>
          </div>
          <!-- conten products price  -->
          <div
            class="display-flex align-items-center border-top-line border-bottom-line pt-2 pb-2"
          >
            <p class="text-price mon-regular">
              {{ texts.viewArticle.textPrice }} {{ textTypeProduct }}
            </p>
            <v-spacer></v-spacer>
            <p class="text-number-price mon-bold">
              ${{ formatMoney(aArticle.dUnitPrice) }} USD
            </p>
          </div>
          <!-- content quantity boxes  -->
          <div class="display-flex align-items-center pt-9 pb-5">
            <div class="display-flex align-items-center">
              <v-btn @click="minAmount" icon>
                <v-icon color="#A8A8A8" size="15px"> mdi-minus </v-icon>
              </v-btn>
              <input
                v-model="amount"
                class="input-amount"
                type="text"
                @keypress="soloNumbers"
                @keyup="ajustAmount"
                @blur="noEmpty"
                :min="aArticle.dMinAmountAllowed"
                maxlength="9"
              />
              <v-btn @click="maxAmount" icon>
                <v-icon color="#A8A8A8" size="15px"> mdi-plus </v-icon>
              </v-btn>
              <div>
                <p class="text-min mon-regular">
                  Min: {{ aArticle.dMinAmountAllowed }}
                </p>
                <p class="text-max mon-regular">
                  Max: {{ aArticle.dInventoryQuantity }}
                </p>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isResponsive"
              @click="addShoppingCart(aArticle.sProductId)"
              class="button-add-cart mon-bold"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="ml-1" color="#283C4D" size="16px">
                mdi-cart-outline
              </v-icon>
            </v-btn>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="addShoppingCart(aArticle.sProductId)"
                  class="button-add-cart mon-bold"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <!-- {{ texts.viewArticle.textAddCart }} -->
                  <v-icon class="ml-1" color="#283C4D" size="16px">
                    mdi-cart-outline
                  </v-icon>
                </v-btn>
              </template>
              <span class="text-min-boxes mon-regular"
                >{{ texts.viewArticle.textMinBoxes }}
                {{ aArticle.dMinAmountAllowed }}
                {{ textTypeProduct }}(s)*</span
              >
            </v-tooltip>
          </div>
          <p
            v-if="isResponsive"
            style="font-size: 12px; color: #9aa6b1;"
            class="mon-bold"
          >
            *{{ texts.viewArticle.textMinBoxes }}
            {{ aArticle.dMinAmountAllowed }} {{ textTypeProduct }}*
          </p>
          <div class="border-bottom-line" />
          <!-- content article description  -->
          <div class="card-content-about mt-3">
            <p class="text-title-about-article mon-bold">
              {{ texts.viewArticle.textTitleAboutArticle }}
            </p>
            <p class="text-title-about-description-article mon-regular">
              {{ aArticle.sDescription }}
            </p>
          </div>
          <!-- content article information  -->
          <div class="card-content-info mt-3">
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                {{ texts.viewArticle.description.text8 }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.sLocationWarehouse }}
              </p>
            </div>
            <div class="border-bottom-line" />
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-calendar </v-icon>
                {{ texts.viewArticle.description.text7 }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.tProductAvailableTimeView }} 
              </p>
            </div>
            <div class="border-bottom-line" />

            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px">
                  mdi-format-list-numbered
                </v-icon>
                {{ selectLanguage == "sp" ? "Inventario" : "Inventory" }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.dInventoryQuantity }} {{ nameBox }}
              </p>
            </div>
            <div class="border-bottom-line" />
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                {{
                  selectLanguage == "sp"
                    ? "Mínimo de artículos a la venta"
                    : "Minimum items for sale"
                }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.dMinAmountAllowed }}
              </p>
            </div>
            <div class="border-bottom-line" />
            <!-- 11 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-weight </v-icon>
                {{ text11() }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ textWeightPerBox }} LBS
              </p>
            </div>
            <div class="border-bottom-line" />
            <!-- 10 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                {{ text10() }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ textPiecesPerBox }}
              </p>
              <!-- <p class="description-text mon-regular">{{ textPiecesPerBox }} {{ textTypeProduct }}(s)</p> -->
            </div>
            <div class="border-bottom-line" />
            <!-- 12 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                {{ text12() }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ textBoxesPerPallet }} {{ textTypeProduct }}(s)
              </p>
            </div>
            <div class="border-bottom-line" />
            <!-- 3 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                {{ texts.viewArticle.description.text3 }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.dStorageTemperature }} F°
              </p>
            </div>
            <div class="border-bottom-line" />
            <!-- 4 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                {{ texts.viewArticle.description.text4 }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.dTransportationTemperature }} F°
              </p>
            </div>
            <div class="border-bottom-line" />
            <!-- 1 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-near-me </v-icon>
                {{ texts.viewArticle.description.text1 }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.sEnterpriseCountryName }}
              </p>
            </div>

            <div class="border-bottom-line" />
            <!-- 2 -->
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                {{ texts.viewArticle.description.text2 }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.sEnterpriseStateName }}
              </p>
            </div>
            <!-- <div class="border-bottom-line" /> -->
            <!-- 6 -->
            <!-- <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon
                    color="#283C4D"
                    size="18px"
                  >
                    mdi-timer-outline
                  </v-icon>
                  {{ texts.viewArticle.description.text6 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.iPreparationTime }} Hrs</p>
              </div> -->
            <div class="border-bottom-line" />
            <div class="display-flex align-items-center pa-5">
              <p class="description-title mon-medium">
                <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                {{ selectLanguage == "sp" ? "Número de lote" : "Lot number" }}
              </p>
              <v-spacer></v-spacer>
              <p class="description-text mon-regular">
                {{ aArticle.sLotId }}
              </p>
            </div>
          </div>
          <br /><br /><br />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="v-container-fluid" fluid v-else>
      <v-row>
        <v-col cols="12" xl="5" lg="5" md="7" sm="12">
          <div v-if="lengthImages > 0">
            <div>
              <div class="display-flex resize-content">
                <div class="content-fixed-image">
                  <div v-for="(item, index) in aArticle.aImages" :key="index">
                    <div
                      @click="selectImage(item)"
                      class="content-image cursor-pointer"
                    >
                      <img :src="item.oImages.md" alt="" />
                    </div>
                  </div>
                </div>
                <div class="content-resize-image">
                  <zoom-image :imageSelected="imageSelected"></zoom-image>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <p class="text-mouse-zoom mon-medium text-center">
                {{ texts.viewArticle.textMouseZoom }}
              </p>
            </div>
          </div>
          <div v-else>
            <div class="content-image-empty">
              <img src="@/assets/images/placeholder-image.jpeg" alt="" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" xl="7" lg="7" md="5" sm="12">
          <div class="pa-2">
            <p class="text-title mon-bold">{{ aArticle.sProductName }}</p>
            <p class="text-location mon-bold">
              <v-icon size="18px" color="#9AA6B1"> mdi-map-marker </v-icon>
              {{ aArticle.sLocationWarehouse }}
            </p>
            <div class="display-flex align-items-center mb-4">
              <div v-if="existProvider !== null">
                <img
                  v-if="lengthLogo > 0"
                  width="70px"
                  height="70px"
                  style="border-radius: 10px;object-fit: contain;background-color: white;"
                  :src="imageProvider"
                  alt=""
                />
                <img
                  v-else
                  width="70px"
                  height="70px"
                  style="border-radius: 10px;object-fit: contain;background-color: #F4F4F4;"
                  src="@/assets/images/placeholder-image.jpeg"
                  alt=""
                />
              </div>
              <div v-if="existProvider !== null" class="ml-5">
                <p class="text-name-warehouse mon-bold">
                  {{ aArticle.sVendorEnterpriseName }}
                </p>
                <v-btn
                  @click="redirecVendorDetail(aArticle.sVendorEnterpriseId)"
                  class="link-button-warehouse mon-medium"
                  text
                >
                  {{ texts.viewArticle.textLinkButtonProductsProvider }}
                  <v-icon class="ml-1" color="#0971FB" size="18px">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div
              v-if="existProvider !== null"
              class="display-flex align-items-center mb-4"
            >
              <v-rating
                :value="aArticle.dRating"
                length="5"
                color="#FFC92A"
                background-color="#C6C6C8"
                dense
                small
                readonly
              ></v-rating>
              <p
                :style="aArticle.dRating == 0 ? { color: '#C6C6C8' } : {}"
                class="text-calif-num mon-bold ml-1 mb-n1"
              >
                {{ aArticle.dRating > 5 ? 5 : aArticle.dRating }}
              </p>
            </div>
            <div
              class="
                display-flex
                align-items-center
                border-top-line border-bottom-line
                pt-2
                pb-2
              "
            >
              <p class="text-price mon-regular">
                {{ texts.viewArticle.textPrice }} {{ textTypeProduct }}
              </p>
              <v-spacer></v-spacer>
              <p class="text-number-price mon-bold">
                ${{ formatMoney(aArticle.dUnitPrice) }} USD
              </p>
            </div>
            <div
              class="
                display-flex
                align-items-center
                border-bottom-line
                pt-9
                pb-9
              "
            >
              <div class="display-flex align-items-center">
                <v-btn @click="minAmount" icon>
                  <v-icon color="#A8A8A8" size="15px"> mdi-minus </v-icon>
                </v-btn>
                <input
                  v-model="amount"
                  class="input-amount"
                  type="text"
                  @keypress="soloNumbers"
                  @change="ajustAmount"
                  @blur="noEmpty"
                  :min="aArticle.dMinAmountAllowed"
                  maxlength="9"
                />
                <v-btn @click="maxAmount" icon>
                  <v-icon color="#A8A8A8" size="15px"> mdi-plus </v-icon>
                </v-btn>
                <div>
                  <p class="text-min mon-regular">
                    Min: {{ aArticle.dMinAmountAllowed }}
                  </p>
                  <p class="text-max mon-regular">
                    Max: {{ aArticle.dInventoryQuantity }}
                  </p>
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="addShoppingCart(aArticle.sProductId)"
                    class="button-add-cart mon-bold"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ texts.viewArticle.textAddCart }}
                    <v-icon class="ml-1" color="#283C4D" size="16px">
                      mdi-cart-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span class="text-min-boxes mon-regular"
                  >{{ texts.viewArticle.textMinBoxes }}
                  {{ aArticle.dMinAmountAllowed }}
                  {{ textTypeProduct }}(s)*</span
                >
              </v-tooltip>
            </div>
            <div class="card-content-about mt-3">
              <p class="text-title-about-article mon-bold">
                {{ texts.viewArticle.textTitleAboutArticle }}
              </p>
              <p class="text-title-about-description-article mon-regular">
                {{ aArticle.sDescription }}
              </p>
            </div>
            <div class="card-content-info mt-3">
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                  {{ texts.viewArticle.description.text8 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.sLocationWarehouse }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-calendar </v-icon>
                  {{ texts.viewArticle.description.text7 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.tProductAvailableTimeView }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-format-list-numbered
                  </v-icon>
                  {{ selectLanguage == "sp" ? "Inventario" : "Inventory" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.dInventoryQuantity }} {{ nameBox }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{
                    selectLanguage == "sp"
                      ? "Mínimo de artículos a la venta"
                      : "Minimum items for sale"
                  }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.dMinAmountAllowed }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 11 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-weight </v-icon>
                  {{ text11() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ textWeightPerBox }} LBS
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 10 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ text10() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ textPiecesPerBox }}
                </p>
                <!-- <p class="description-text mon-regular">{{ textPiecesPerBox }} {{ textTypeProduct }}(s)</p> -->
              </div>
              <div class="border-bottom-line" />
              <!-- 12 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ text12() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ textBoxesPerPallet }} {{ textTypeProduct }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 3 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                  {{ texts.viewArticle.description.text3 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.dStorageTemperature }} F°
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 4 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                  {{ texts.viewArticle.description.text4 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.dTransportationTemperature }} F°
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 1 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-near-me </v-icon>
                  {{ texts.viewArticle.description.text1 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.sEnterpriseCountryName }}
                </p>
              </div>

              <div class="border-bottom-line" />
              <!-- 2 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                  {{ texts.viewArticle.description.text2 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.sEnterpriseStateName }}
                </p>
              </div>
              <!-- <div class="border-bottom-line" /> -->
              <!-- 6 -->
              <!-- <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon
                    color="#283C4D"
                    size="18px"
                  >
                    mdi-timer-outline
                  </v-icon>
                  {{ texts.viewArticle.description.text6 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.iPreparationTime }} Hrs</p>
              </div> -->
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ selectLanguage == "sp" ? "Número de lote" : "Lot number" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.sLotId }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { parse } from "path";

export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "ViewArticleContentLayout",
  data() {
    return {
      aArticle: [],
      imageSelected: "",
      textTypeProduct: "",
      textPiecesPerBox: "",
      textWeightPerBox: "",
      textBoxesPerPallet: "",
      amount: 0,
      lengthImages: 0,
      nameBox: "",
      imageProvider: "",
      lengthLogo: 0,
      existProvider: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getArticles();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function() {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    redirectLink: function() {
      let link =
        "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TABULADOR.pdf";
      window.open(link, "blank");
    },
    text10: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Piezas por " + this.nameBox;
        } else {
          text = "Aprox pieces per " + this.nameBox;
        }
      return this.capitalize(text);
    },
    text11: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Peso por " + this.nameBox;
        } else {
          text = "Weight per " + this.nameBox;
        }
      return this.capitalize(text);
    },
    text12: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Número de " + this.nameBox + " Por pallet";
        } else {
          text = "Number of " + this.nameBox + " Per pallet";
        }
      return this.capitalize(text);
    },
    capitalize: function(str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    formatDate: function(date) {
      var new_date = MOMENT(date, "DD/MM/YYYY");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    redirecVendorDetail: function(id) {
      this.$store.commit("setCommingFromCart", true);
      this.$router.push(`/vendor/detail/${id}`);
    },
    addShoppingCart: function(sProductId) {
      if (this.$store.state.isLogged) {
        const payload = {
          sProductId: sProductId,
          dQuantity: this.amount,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
          payload,
          config
        )
          .then((response) => {
            this.mixSuccess(response.data.message);
            this.$store.commit("setFadeNotificationCart", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.mixError(this.texts.viewArticle.textNoSession);
      }
    },
    getArticles: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.$route.params.id}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
          params: {
            bCustomer: true,
            aImagesSizes: "sm,md,lg",
          },
        }
      )
        .then((response) => {
          let arr = response.data.results;
          this.aArticle = arr;
          this.textTypeProduct = arr.oMeasurementUnit.sName;
          this.textPiecesPerBox = arr.oMeasurementUnit.oData.dPiecesPerBox;
          this.textWeightPerBox = arr.oMeasurementUnit.oData.dWeightPerBox;
          this.textBoxesPerPallet = arr.oMeasurementUnit.oData.dBoxesPerPallet;
          this.imageSelected = arr.aImages[0].oImages.lg;
          this.lengthImages = arr.aImages.length;
          this.amount = arr.dMinAmountAllowed;
          this.nameBox = arr.oMeasurementUnit.sName;
          this.lengthLogo = Object.keys(arr.sLogoVendorEnterpriseKey).length;
          this.imageProvider = arr.sLogoVendorEnterpriseKey.sm;
          this.existProvider = arr.sVendorEnterpriseId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectImage: function(e) {
      this.imageSelected = e.oImages.lg;
    },
    minAmount: function() {
      if (this.amount > this.aArticle.dMinAmountAllowed) {
        this.amount--;
      }
    },
    maxAmount: function() {
      if (this.amount < this.aArticle.dInventoryQuantity) {
        this.amount++;
      }
    },
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    ajustAmount: function(evt) {
      if (evt.target.value === "" || evt.target.value === null) {
        this.amount = this.aArticle.dMinAmountAllowed;
      } else if (
        parseInt(evt.target.value) === 0 ||
        parseInt(evt.target.value) < this.aArticle.dMinAmountAllowed
      ) {
        this.amount = this.aArticle.dMinAmountAllowed;
      }
    },
    noEmpty: function(evt) {
      if (evt.target.value == "") {
        this.amount = this.aArticle.dMinAmountAllowed;
      }
    },
    // ajustAmount: function (evt) {
    //   if (Number(evt.target.value) >= 100) {
    //     this.amount = 100;
    //   } else if (evt.target.value == "00") {
    //     this.amount = aArticle.dMinAmountAllowed;
    //   }
    // },
    // noEmpty: function (evt) {
    //   if (evt.target.value == "") {
    //     this.amount = aArticle.dMinAmountAllowed;
    //   }
    // },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.getArticles();
      }
    },
    //VARIBALKES
    $route(to, from) {
      this.getArticles();
    },
  },
};
</script>

<style scoped>
.link-hover {
  color: #0971fa !important;
}

.color-blue-information {
  color: #0971fa !important;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-title {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
}

.text-name-warehouse {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.link-button-warehouse {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  text-transform: initial;
  padding: 0px !important;
}

.text-calification {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-reviews {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.border-top-line {
  border-top: 1px solid #c3c3c3;
}

.border-bottom-line {
  border-bottom: 1px solid #c3c3c3;
}

.text-min {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-max {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-number-price {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.button-add-cart {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 10px;
  height: 40px !important;
  font-size: 14px;
  text-transform: initial;
}

.input-amount {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  height: 40px;
  width: 80px;
  outline: none;
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

.card-content-about {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.card-content-info {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.text-title-about-article {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.text-title-about-description-article {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.description-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.description-text {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

/* Chrome, Safari, Edge, Opera */
.input-amount::-webkit-outer-spin-button,
.input-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-amount[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.text-mouse-zoom {
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

/* images min */
.content-fixed-image {
  width: 125px;
  max-height: 350px;
  overflow: auto;
  padding: 10px;
}

.content-image-empty {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.content-image-empty img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-empty img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
    border: 1px solid #00000029;
  }
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

/* image zoom */

.content-resize-image {
  /* background-color: green; */
  width: calc(100% - 125px);
  height: 350px;
  padding: 10px;
  border: 1px solid #00000029;
  border-radius: 23px;
  /* background-color: #000000; */
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }

  .content-fixed-image {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .content-fixed-image .content-image {
    width: 130px;
    margin: 1px;
    padding: 4px;
  }

  .content-resize-image {
    width: 100% !important;
  }
}

@media (max-width: 960px) {
  .content-resize-image {
    width: 100%;
  }

  .text-price,
  .text-number-price {
    font-size: 18px;
  }
}

.scrolled-lateral-images {
  margin-top: 15px;
  overflow-x: scroll;
  display: flex;
}
</style>
