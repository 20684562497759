<template>
  <div>
    <v-container class="v-container-fluid" fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="container-card">
            <div class="d-flex">
              <p class="content-card-text-title mon-medium">
                {{ ocheckout.sName }}
              </p>
              <v-spacer></v-spacer>
              <div class="display-flex">
                <v-icon class="pb-3" color="#b2b2b2" size="16px">
                  mdi-map-marker
                </v-icon>
                <p class="text-provider-location mon-regular">
                  {{ ocheckout.aProducts[0].sWarehouseCityAndState }}
                </p>
              </div>
            </div>
            <!-- #region productos -->

            <!-- #region table - lap -->
            <div
              v-if="!bMobile"
              v-for="(item, index) in ocheckout.aProducts"
              :key="index"
              class="
                  display-flex
                  align-items-center
                  border-top border-bottom
                  pt-3
                  pb-3
                "
            >
              <div class="content-image">
                <img
                  v-if="item.sImageUrl !== ''"
                  :src="item.sImageUrl"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/images/placeholder-image.jpeg"
                  alt=""
                />
              </div>
              <p class="content-card-text-info mon-regular ml-2">
                {{ item.sProductName }}
              </p>
              <v-spacer></v-spacer>
              <p class="content-card-text-info mon-regular">
                {{ item.dQuantity }} Cajas
              </p>
              <p class="content-card-text-info mon-regular ml-9">
                ${{ formatMoney(item.dUnitPrice) }} USD
              </p>
              <p class="content-card-text-info mon-regular ml-9">
                ${{ formatMoney(item.dTotalPrice) }} USD
              </p>
            </div>
            <!-- #endregion table - lap -->

            <!-- #region mobile -->

            <div
              v-else
              v-for="(item, i) in ocheckout.aProducts"
              :key="i"
              class="
                  align-items-center
                  border-top border-bottom
                  pt-3
                  pb-3
                "
            >
              <span class="d-flex">
                <div class="content-image">
                  <img
                    v-if="item.sImageUrl !== ''"
                    :src="item.sImageUrl"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/images/placeholder-image.jpeg"
                    alt=""
                  />
                </div>
                <span class="content-card-text-info mon-regular ml-2">
                  {{ item.sProductName }}
                </span>
              </span>

              <v-spacer></v-spacer>
              <div class="d-flex justify-end">
                <p class="content-card-text-info mon-regular">
                  {{ item.dQuantity }} Cajas
                </p>
                <p class="content-card-text-info mon-regular ml-9">
                  ${{ formatMoney(item.dUnitPrice) }} USD
                </p>
                <p class="content-card-text-info mon-regular text-end ml-9">
                  ${{ formatMoney(item.dTotalPrice) }} USD
                </p>
              </div>
            </div>

            <!-- #endregion mobile -->

            <!-- #endregion productos -->
            <div
              class="display-flex align-items-center justify-content-flex-end"
            >
              <p class="content-card-text-info  mon-bold">
                {{ costsAduanaName }}
              </p>
              <p class="content-card-text-info mon-regular ml-9">
                ${{ formatMoney(costsAduana) }} USD
              </p>
            </div>
            <div
              class="display-flex align-items-center justify-content-flex-end"
            >
              <p class="content-card-text-info  mon-bold">
                {{ costsInOutName }}
              </p>
              <p class="content-card-text-info mon-regular ml-9">
                ${{ formatMoney(costsInOut) * sumPallets() }} USD
              </p>
            </div>
            <div
              class="
                  display-flex
                  align-items-center
                  justify-content-flex-end
                  mt-5
                "
            >
              <div class="display-flex align-items-center container-red">
                <p class="content-card-text-total mon-semibold">Subtotal</p>
                <p class="content-card-text-total mon-semibold ml-9">
                  ${{
                    formatMoney(
                      formatMoneySubtotal(
                        ocheckout.aProducts,
                        costsInOut * sumPallets(),
                        costsAduana
                      )
                    )
                  }}
                  USD
                </p>
              </div>
            </div>
            <div class="display-flex align-items-center mt-3">
              <v-icon
                style="margin-top: 0px; margin-right: 2px"
                color="rgb(255, 0, 0)"
                size="16px"
              >
                mdi-information
              </v-icon>
              <p class="pallets-alert ml-1 text-message mon-regular">
                {{ 26 - sumPallets() }}
                {{ texts.paymentsprocess.message.textPalletsOutOf }} 26
                {{ texts.paymentsprocess.message.textPalletsTotals }} (
                <a
                  text
                  @click="redirectToVendor()"
                  class="shop-supplier"
                  :href="'/vendor/detail/' + ocheckout.sProviderId"
                >
                  {{ texts.paymentsprocess.shopSupplier }}
                </a>
                )
              </p>
            </div>
            <!-- <div v-else class="display-flex align-items-center mt-3">
                <v-icon color="#000" size="16px"> mdi-information </v-icon>
                <p class="ml-1 text-message mon-regular">
                  {{ texts.paymentsprocess.message2.text1 }}
                  {{ item.trailers }} {{ texts.paymentsprocess.message2.text2 }}
                </p>
              </div> -->
          </div>
          <div class="display-flex align-item-center mr-3 mt-6">
            <p class="text-total mon-bold">
              {{ texts.paymentsprocess.textTotal }}
            </p>
            <v-spacer></v-spacer>
            <p class="text-total-price mon-bold">
              ${{
                formatMoney(
                  formatMoneySubtotal(
                    ocheckout.aProducts,
                    costsInOut * sumPallets(),
                    costsAduana
                  )
                )
              }}
              USD
            </p>
          </div>
          <div class="content-card mr-3 mt-2 mb-5">
            <div class="display-flex align-items-center">
              <p class="text-costs mon-regular">
                {{ texts.paymentsprocess.textCost1 }}
              </p>
            </div>
          </div>
          <div class="content-card mr-3 mb-5">
            <p class="text-tabulador mon-regular">
              {{ texts.paymentsprocess.textFlete1 }}
              <label @click="redirectTabulator" class="text-link">
                {{ texts.paymentsprocess.textFlete2 }}
              </label>
            </p>
          </div>
          <div
            class="display-flex mr-3 align-items-center mon-bold justify-end mt-3"
          >
            <v-icon color="#0971fb" size="18px">mdi-arrow-left</v-icon>
            <p
              @click="
                $router.push({
                  name: 'Article',
                  params: {
                    idCategory: 'all',
                    idSubcategory: 'b87bbcf26818fb2e6262fffeab402f03',
                  },
                })
              "
              class="text-return"
            >
              {{
                selectLanguage == "sp"
                  ? "Sigue comprando en PROPLAT"
                  : "Keep shopping at PROPLAT"
              }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PaymentProcessContentLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      orders: [],
      total: 0,
      costsAduana: 0,
      costsInOut: 0,
      costsAduanaName: "",
      costsInOutName: "",
      arrAllOrders: [],
      resp: 0,
      aTotal: [],
      bMobile: false,
    };
  },
  beforeMount() {
    this.getShoppingCart();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.bMobile = window.innerWidth < 599.99;
    },
    sumPallets() {
      let sumPal = 0;
      for (let i = 0; i < this.ocheckout.aProducts.length; i++) {
        const element = this.ocheckout.aProducts[i].dPallets;
        sumPal = sumPal + parseFloat(element);
      }
      return sumPal;
    },
    redirectToVendor() {
      this.$store.commit("setCommingFromCart", true);
    },
    getIntOutCost: function(costsInOut, order) {
      let sum = 0;
      sum = costsInOut * order.dPallets;

      return sum.toFixed(2);
    },
    redirectTabulator: function() {
      window.open(
        "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TABULADOR.pdf",
        "blank"
      );
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    formatMoneySubtotal: function(aSubtotal, dCostsAduana, dCostsInOut) {
      let dSubtotal = 0;
      for (let i = 0; i < aSubtotal.length; i++) {
        const element = aSubtotal[i];
        dSubtotal = dSubtotal + element.dTotalPrice;
      }
      let total_subtotal = parseFloat(
        dSubtotal + dCostsAduana + dCostsInOut
      ).toFixed(2);
      return total_subtotal;
    },
    getTotal: function() {
      let sum = 0;
      for (let i = 0; i < this.aTotal.length; i++) {
        const element = this.aTotal[i];
        sum = sum + parseFloat(element);
      }
      return FORMAT_MONEY(sum);
    },
    getShoppingCart: function() {
      const payload = {};
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
        config,
        payload
      )
        .then((response) => {
          let self = this;
          if (response.data.results.length > 0) {
            let oProviderTemp = response.data.results.find(
              (e) => e.sProviderId === this.ocheckout.sProviderId
            );
            if (oProviderTemp) {
              this.$store.commit("updateCheckout", oProviderTemp);

              this.arrAllOrders = response.data.results;
              let warehouses = response.data.results.reduce((a, b) => {
                let item = a.find(
                  (el) => el.sVendorWarehouseId == b.sVendorWarehouseId
                );
                let prod = [b].map((e, i) => {
                  let pallets = Math.ceil(e.dQuantity / e.dBoxesPerPallet);
                  return {
                    ...e,
                    iPalletsProducts: pallets == 1 ? 1 : pallets,
                  };
                });
                if (!item) {
                  let xPallets = prod.reduce((a, b) => {
                    return a + b.iPalletsProducts;
                  }, 0);
                  return [
                    ...a,
                    {
                      sVendorWarehouseId: b.sVendorWarehouseId,
                      sTitleOrder: b.sProviderName,
                      products: prod,
                      totalPallets: prod.reduce((a, b) => {
                        return a + b.iPalletsProducts;
                      }, 0),
                      trailers: Math.ceil(xPallets / 26),
                      missing: Math.ceil(xPallets / 26) * 26 - xPallets,
                    },
                  ];
                }

                item.products.push(prod[0]);
                item.totalPallets = item.products.reduce((a, b) => {
                  return a + b.iPalletsProducts;
                }, 0);
                item.trailers = Math.ceil(item.totalPallets / 26);
                item.missing =
                  Math.ceil(item.totalPallets / 26) * 26 - item.totalPallets;

                return a;
              }, []);

              warehouses = warehouses.map((i) => {
                return {
                  ...i,
                  iTotalOrder:
                    i.products.length > 1
                      ? i.products
                          .map((e) => e.dTotalPrice)
                          .reduce((a, b) => {
                            return a + b;
                          }, 0)
                      : i.products[0].dTotalPrice,
                };
              });

              let sum = 0;
              response.data.results.forEach((element) => {
                sum = sum + element.dTotalPrice;
              });

              this.orders = warehouses;
              this.costsAduana = response.data.fees.find(
                (e) =>
                  e.sFeeName === "Custom process" ||
                  e.sFeeName === "Proceso Aduanal"
              ).dQuantity;
              this.costsAduanaName = response.data.fees.find(
                (e) =>
                  e.sFeeName === "Custom process" ||
                  e.sFeeName === "Proceso Aduanal"
              ).sFeeName;

              this.costsInOut = response.data.fees.find(
                (e) =>
                  e.sFeeName === "In&Out process" ||
                  e.sFeeName === "Proceso In&Out"
              ).dQuantity;
              this.costsInOutName = response.data.fees.find(
                (e) =>
                  e.sFeeName === "In&Out process" ||
                  e.sFeeName === "Proceso In&Out"
              ).sFeeName;

              let suma_in_out = 0,
                suma_subtotales = 0;

              for (let i = 0; i < this.orders.length; i++) {
                suma_in_out = this.orders[i].totalPallets * this.costsInOut;
                suma_subtotales =
                  suma_in_out + this.orders[i].iTotalOrder + this.costsAduana;
                suma_subtotales = suma_subtotales.toFixed(2);
                this.aTotal.push(suma_subtotales);
              }

              this.total =
                sum + (this.costsAduana + this.costsInOut) * this.orders.length;
            } else {
              this.$router.push({ name: "ShoppingCart" });
            }
          } else {
            this.$router.push({ name: "ShoppingCart" });
          }
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getPalletsPerProduct: function() {
      let trans = this.orders.totalPallets;

      //   let sum = this.arrAllOrders.map((e) => {
      //     return {
      //       ...e,
      //       palletsPerProducts: Math.ceil(e.dQuantity / e.dBoxesPerPallet),
      //     };
      //   });
      //   let sumPalletsProducts = sum.reduce((a, b) => {
      //     return a + b.palletsPerProducts;
      //   }, 0);
      //   let camiones = parseFloat(sumPalletsProducts / 26).toFixed(2);
    },
  },
  computed: {
    refresh: function() {
      return this.$store.state.refresh;
    },
    ocheckout() {
      return this.$store.state.ocheckout;
    },
    selectLanguage: function() {
      return this.$store.state.language;
    },
  },
  watch: {
    refresh: function() {
      this.getShoppingCart();
    },
  },
};
</script>

<style scoped>
.pallets-alert {
  /* color: rgb(0, 192, 48) !important; */
  color: rgb(255, 0, 0) !important;
  font-weight: bold;
}

.text-return {
  color: #0971fb;
  margin-bottom: 0;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
}

.text-return:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-message {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.content-card-text-title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.container-red {
  /* background-color: #ffa77e80; */
  background: transparent linear-gradient(228deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px;
}

.content-card-text-total {
  text-align: right;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.content-card-text-info {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
  line-height: 32px;
}

.border-bottom {
  border-bottom: 1px solid #d5d5d5;
}

.border-top {
  border-top: 1px solid #d5d5d5;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 15px;
}

.content-image {
  width: 37px;
  min-width: 37px;
  max-width: 37px;
  height: 37px;
  min-height: 37px;
  max-height: 37px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 10px;
  }
}

.content-cards {
  max-height: 390px;
  width: 100%;
  margin-top: 20px;
  overflow: auto;
}

.text-tabulador {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.text-link {
  text-decoration: underline;
  color: #0971fb;
}

.text-link:hover {
  cursor: pointer;
}

.text-costs {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
}

.text-total {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.text-total-price {
  text-align: right;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.display-flex {
  display: flex;
}

.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .text-message {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 0px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
