<template>
  <div>
    <v-container class="v-container-fluid" fluid>
      <!-- skeleton loader -->
      <article-skeleton-layout v-if="loaderSkeleton" />
      <v-row v-else>
        <v-col cols="12">
          <!-- content cards -->
          <div v-if="aArticles.length > 0">
            <v-row>
              <v-col v-for="(item, index) in aArticles" :key="index" cols="12" xl="2" lg="3" md="4" sm="6" xs="12">
                <div class="card-container" >
                  <div class="content-image" :class="item.aImages.length === 0? 'back-grey-img':'back-black-img'" >
                    <img @click="getDetail(item.sProductId )" style="cursor: pointer;" v-if="item.aImages.length > 0"
                      :src="item.aImages[0].oImages.md" alt="" />
                    <img v-else src="@/assets/images/placeholder-image.jpeg" alt="" />
                  </div>
                  <div class="mt-5">
                    <p :style="{ 'min-height': `${restantHeightName}px` }" class="text-title text-name-box mon-medium">{{ item.sName }}</p>
                    <p v-if="item.sVendorEnterpriseName" class="text-provider mon-bold">
                      <v-icon size="15px" color="#9AA6B1"> mdi-account </v-icon>
                      {{ item.sVendorEnterpriseName }}
                    </p>
                    <p class="text-location mon-bold">
                      <v-icon size="15px" color="#9AA6B1">
                        mdi-map-marker
                      </v-icon>
                      {{ item.sLocationWarehouse }}
                    </p>
                    <div class="display-flex align-items-center mb-3">
                      <v-icon size="15px" color="#FFC92A"> mdi-star </v-icon>
                      <v-rating v-model="item.dRating" readonly background-color="#C6C6C8" color="#FFC107" dense
                        half-increments length="5" size="14"></v-rating>
                      <p class="text-number-stars mon-bold ">{{ (item.dRating > 5)? 5 : item.dRating }}</p>
                      <!-- <p class="text-number-reviws mon-medium ml-2">(15 {{ texts.article.card.textReviews }})</p> -->
                    </div>
                    <p :style="{ 'min-height': `${restantHeightPrice}px` }" class="text-price-box mon-bold">
                      ${{ formatMoney(item.dUnitPrice) }} USD /
                      {{ item.oMeasurementUnit.sName }}
                    </p>
                    <div class="display-flex align-items-center">
                      <div v-if="$store.state.sToken" class="justify-content-flex-start width-50 mr-1">
                        <v-btn v-if="isResponsive" @click="addShoppingCart(item.sProductId, item.dMinAmountAllowed)"
                          elevation="0" class="button-add mon-bold">
                          {{ texts.article.card.buttonAdd }}
                          <v-icon class="ml-1" size="16px">
                            mdi-cart-outline
                          </v-icon>
                        </v-btn>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="
                              addShoppingCart(
                                item.sProductId,
                                item.dMinAmountAllowed
                              )
                            " elevation="0" class="button-add mon-bold" v-bind="attrs" v-on="on">
                              {{ texts.article.card.buttonAdd }}
                              <v-icon class="ml-1" size="16px">
                                mdi-cart-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span class="text-min-boxes mon-regular">{{ texts.article.textMinBoxes }}
                            {{ item.dMinAmountAllowed }}
                            {{ item.oMeasurementUnit.sName }}(s)*</span>
                        </v-tooltip>
                      </div>
                      <div :class="($store.state.sToken)? 'width-50 ml-1 ':'width-100'"
                        class="justify-content-flex-end ">
                        <v-btn @click="getDetail(item.sProductId )" elevation="0" class="button-view mon-bold">
                          {{ texts.article.card.buttonShow }}
                          <v-icon class="ml-1" size="16px">
                            mdi-arrow-right
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <p v-if="isResponsive" class="text-location mon-bold mt-2 mb-0">
                      *{{ texts.article.textMinBoxes }} {{ item.dMinAmountAllowed }} {{ item.oMeasurementUnit.sName }}*
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                  <div class="pagination-styles">
                    <v-pagination v-model="iCurrentPage" :length="lengthPage" color="#FFC556" next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"></v-pagination>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <div v-if="aArticles.length <= 6" style="height: 150px" />
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <div class="
                empty-content
                display-flex
                align-items-center
                justify-content-center
              ">
              <div>
                <div class="display-flex justify-content-center">
                  <img width="170px" src="@/assets/icons/empty.gif" alt="proplat-image" />
                </div>
                <p class="text-empty mon-regular mb-9" v-html="texts.article.textEmptyContent" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "ArticleContentLayout",
  data() {
    return {
      //VARIABLES
      aArticles: [],
      lengthPage: 0,
      iCurrentPage: 1,
      loaderSkeleton: true,
      restantHeightName: 0,
      restantHeightPrice: 0,
      isResponsive: false,
    };
  },
  beforeMount() {
    // this.$store.commit("setSearch", "");
    // this.$store.commit("setCategoryIdFilter", "");
    // this.$store.commit("setSubcategoryIdFilter", "");

    if (
      this.$route.params.idCategory == "all" &&
      this.$route.params.idSubcategory == "b87bbcf26818fb2e6262fffeab402f03"
    ) {
      this.$store.commit("setCategoryIdFilter", "");
      this.$store.commit("setSubcategoryIdFilter", "");
    } else {
      this.$store.commit("setCategoryIdFilter", this.$route.params.idCategory);
      this.$store.commit(
        "setSubcategoryIdFilter",
        this.$route.params.idSubcategory
      );
    }

    this.getArticles();
  },
  updated() {
    this.resizeDivPrice();
    this.resizeDivName();
  },
  created() {
    window.addEventListener("resize", this.resizeDivName);
    this.resizeDivName();
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDivName);
    window.removeEventListener("resize", this.resizeDivPrice);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getDetail(sProductId) {
      this.$router.push({
        name: 'ViewArticle',
        params: { id: sProductId },
      })
    },
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    resizeDivName: function () {
      var contentHeight = document.getElementsByClassName("text-name-box");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightName = max;
    },
    resizeDivPrice: function () {
      var contentHeight = document.getElementsByClassName("text-price-box");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightPrice = max;
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    addShoppingCart: function (sProductId, min) {
      if (this.$store.state.isLogged) {
        const payload = {
          sProductId: sProductId,
          dQuantity: min,
          // sCustomerId: this.$store.state.sUserId
        };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
          payload,
          config
        )
          .then((response) => {
            this.mixSuccess(response.data.message);
            this.$store.commit("setFadeNotificationCart", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.mixError(this.texts.article.textNoSession);
      }
    },
    getArticles: function () {
      this.loaderSkeleton = true;

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/products?sCategoryId=${this.sCategoryIdFilter}&sSubcategoryId=${this.sSubcategoryIdFilter}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
          params: {
            // sCategoryId: this.sCategoryIdFilter,
            // sSubcategoryId: this.sSubcategoryIdFilter,
            sSearch: this.sSearch,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: 6,
          },
        }
      )
        .then((response) => {
          this.loaderSkeleton = false;

          this.aArticles = response.data.results;

          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    sFilter: function () {
      return this.$store.state.sFilter;
    },
    sCategoryIdFilter: function () {
      return this.$store.state.sCategoryIdFilter;
    },
    sSubcategoryIdFilter: function () {
      return this.$store.state.sSubcategoryIdFilter;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getArticles();
      }
    },
    refresh: function () {
      this.getArticles();
    },
    sSearch: lodash.debounce(function (val) {
      this.iCurrentPage = 1;
      this.getArticles();
    }, 500),
    sCategoryIdFilter: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sSubcategoryIdFilter: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    iCurrentPage: function () {
      this.getArticles();
    },
  },
};
</script>

<style scoped>

.back-grey-img{
  /* background-color: #F4F4F4 !important; */
}
.back-black-img{
  /* background-color: black !important; */
}
.text-min-boxes {
  font-size: 12px;
}

.button-view {
  background: transparent linear-gradient(250deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  height: 35px !important;
  font-size: 14px;
  text-transform: initial;
  width: 100%;
}

.button-add {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 35px !important;
  font-size: 14px;
  text-transform: initial;
  width: 100%;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 19px;
  opacity: 1;
  padding: 15px;
}

.content-image {
  width: 100%;
  height: 250px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

.text-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 5px;
}

.text-provider {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
}

.text-location {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
}

.text-number-stars {
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-number-reviws {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price-box {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.text-empty {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}

.empty-content {
  width: 100%;
  height: 400px;
}

.width-50 {
  width: 50%;
}

@media (max-width: 600px) {
  .content-image {
    height: 350px;
  }
}
</style>