<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
    <menu-component></menu-component>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      //VARIABLES
    };
  },
};
</script>

<style>
/* import fonts */
@font-face {
  font-family: "montserrat-black";
  src: url("../src/assets/fonts/Montserrat-Black.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-bold";
  src: url("../src/assets/fonts/Montserrat-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-light";
  src: url("../src/assets/fonts/Montserrat-Light.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-medium";
  src: url("../src/assets/fonts/Montserrat-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-regular";
  src: url("../src/assets/fonts/Montserrat-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-semibold";
  src: url("../src/assets/fonts/Montserrat-SemiBold.ttf") format("opentype");
}

/* global styles */
html,
body {
  margin: 0;
  font-family: "monserrat-regular", sans-serif;
  position: relative;
}

.v-application {
  font-family: "monserrat-regular", sans-serif;
}

/* use fonts in the project through css classes */
.mon-black {
  font-family: "montserrat-black", sans-serif;
}

.mon-bold {
  font-family: "montserrat-bold", sans-serif;
}

.mon-light {
  font-family: "montserrat-light", sans-serif;
}

.mon-medium {
  font-family: "montserrat-medium", sans-serif;
}

.mon-regular {
  font-family: "montserrat-regular", sans-serif;
}

.mon-semibold {
  font-family: "montserrat-semibold", sans-serif;
}

/* add scrolls */
scrollbar {
  overflow: auto;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #f2f2f4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #151d28;
}

@media (max-width: 960px) {
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

/* inputs global style */
.global-inputs .v-text-field__details {
  display: none;
}

.global-inputs .v-input__slot {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 13px;
}

.global-inputs fieldset {
  border: 1px solid #e5e5e5 !important;
}

.global-inputs input {
  color: #000000 !important;
  letter-spacing: 0px !important;
}

.global-inputs .v-select__slot .v-label,
.global-inputs .v-text-field__slot .v-label {
  font-size: 13px;
}

/* Pagination Styles */
.pagination-styles .theme--light.v-pagination .v-pagination__item--active {
  color: #283c4d !important;
  font-size: 13px;
}

.pagination-styles .v-pagination__item,
.pagination-styles .v-pagination__navigation {
  height: 35px;
  width: 35px;
  font-size: 14px !important;
}

.pagination-styles {
  width: 310px;
}
/* global buttons */
.button-secondary {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #283c4d;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #283c4d !important;
  height: 40px !important;
  text-transform: initial !important;
  background-color: transparent !important;
}

.button-primary {
  height: 40px !important;
  text-transform: initial !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #283c4d !important;
}

.button-tertiary {
  height: 40px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #f50017 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px !important;
  color: #f50017 !important;
  text-transform: initial !important;
  background-color: transparent !important;
}

.button-primary:disabled,
.button-secondary:disabled,
.button-tertiary:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

/* texts calification style */
.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

/* empty text  */
.text-empty-home {
  text-align: left;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  font-size: 20px;
  margin-bottom: 0;
}

/* align text clases */

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.width-90 {
  width: 90%;
}

.v-container-fluid {
  max-width: 90% !important;
}

@media (max-width: 960px) {
  .v-container-fluid {
    max-width: 100% !important;
  }
}
</style>
