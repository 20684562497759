<template>
  <div>
    <v-container class="v-container-fluid" fluid>
      <!--#region CONTENIDO DE BOTON DE RETORNO -->
      <v-row>
        <v-col cols="12" md="12">
          <div>
            <!-- button return -->
            <v-btn @click="$router.push({ name: 'Order' })" text class="pl-0">
              <v-icon class="icon-return" size="15px"> mdi-arrow-left </v-icon>
              <span class="text-return mon-regular">{{
                  texts.orderdetail.buttonReturn
              }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!--#endregion CONTENIDO DE BOTON DE RETORNO -->


      <!--#region CONTENIDO INFORMATIVO DE FOLIO DE PEDIDO DE VENTA -->
      <v-row>
        <v-col cols="12" md="3" sm="12">
          <div>
            <p class="text-folio mon-bold">{{ aOrders.sFolioId }}</p>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="12">
          <p class="text-creation mon-regular mr-5">
            {{ texts.orderdetail.labelCreationText }}
          </p>
          <p class="text-date mon-regular mr-9">{{ aOrders.tCreatedAtView }}</p>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <p class="text-aprox mon-regular mr-5">
            {{ texts.orderdetail.labelAproxDate }}
          </p>
          <p class="text-aprox-date mon-regular">
            {{
                aOrders.tArrivalAtBorderWarehouseView !== null
                  ? aOrders.tArrivalAtBorderWarehouseView
                  : "N/A"
            }}
          </p>
          <!-- <p class="text-date mon-regular mr-9">{{ namePay }}</p> -->
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <div class="align-items-center height-100 content-gray">
            <p class="text-label mon-regular text-start">
              {{ selectLanguage == "sp" ? "Estado" : "Status" }}
            </p>
            <p class="text-label-item mon-regular">{{ nameStatus }}</p>
          </div>
        </v-col>
      </v-row>
      <!--#endregion CONTENIDO INFORMATIVO DE FOLIO DE PEDIDO DE VENTA -->

      <!--#region CONTENIDO DE ARTICULOS DEL PEDIDO DE VENTA -->
      <v-row>
        <v-col>
          <div>
            <p class="text-title-card mon-bold">
              {{ texts.orderdetail.labelProducts }}
            </p>
            <v-sheet v-if="loaderSkeleton" color="white lighten-4" style=" border-radius: 9px !important;
                box-shadow: 0px 3px 6px #00000029;
              " class="pa-1 full-height-image-skeleton">
              <v-skeleton-loader class="mx-auto" height="250px" type="article"></v-skeleton-loader>
            </v-sheet>
            <div v-else class="content-shadow">
              <p class="text-warehouse-buyer mon-semibold">
                {{ nameProvider }}
              </p>
              <div class="border-bottom" />
              <v-container fluid>
                <v-data-table class="table-article-order elevation-0" :headers="texts.orderdetail.aHeaderArticle"
                  :hide-default-footer="true" :items="aOrders.aPurchaseProducts" :items-per-page="999999">
                  <template v-slot:[`item.sProductName`]="{ item }">
                    <div class="display-flex align-items-center">
                      <div class="content-image">
                        <img :src="item.sImageUrl" alt="" />
                      </div>
                      <p class="text-order-product mon-regular ml-2">
                        {{ item.sProductName }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.dDeliveredQuantity`]="{ item }">
                    <p class="text-order-product mon-regular">
                      {{ item.dDeliveredQuantity }}
                      {{ item.sMeasurementUnitName }}(s)
                    </p>
                  </template>
                  <template v-slot:[`item.dDeliveredUnitPrice`]="{ item }">
                    <p class="text-order-product mon-regular ">
                      ${{ formatMoney(item.dDeliveredUnitPrice) }} USD
                    </p>
                  </template>
                  <template v-slot:[`item.dFinalPrice`]="{ item }">
                    <p class="text-order-product mon-regular text-right">
                      ${{ formatMoney(item.dFinalPrice) }} USD
                    </p>
                  </template>
                </v-data-table>
                <!-- <v-row>
                  <v-col>
                    <p class="text-order-label mon-medium text-left">
                      {{ texts.orderdetail.sectionArticle.text1 }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-label mon-medium text-right">
                      {{ texts.orderdetail.sectionArticle.text2 }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-label mon-medium text-right">
                      {{ texts.orderdetail.sectionArticle.text3 }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-label mon-medium text-right">
                      {{ texts.orderdetail.sectionArticle.text4 }}
                    </p>
                  </v-col>
                </v-row> -->
                <!-- <v-row v-for="(item, index) in aOrders.aPurchaseProducts" :key="index">
                  <v-col class="products-vendor">
                    <div class="display-flex align-items-center">
                      <div class="content-image">
                        <img :src="item.sImageUrl" alt="" />
                      </div>
                      <p class="text-order-product mon-regular ml-2">
                        {{ item.sProductName }}
                      </p>
                    </div>
                  </v-col>
                  <v-col>
                    <p class="text-order-product mon-regular text-right">
                      {{ item.dDeliveredQuantity }}
                      {{ item.sMeasurementUnitName }}(s)
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-product mon-regular text-right">
                      ${{ formatMoney(item.dDeliveredUnitPrice) }} USD
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-product mon-regular text-right">
                      ${{ formatMoney(item.dFinalPrice) }} USD
                    </p>
                  </v-col>
                </v-row> -->
              </v-container>
              <div class="border-bottom" />
              <div class="
                  display-flex
                  align-items-center
                  justify-content-flex-end
                  mt-4
                ">
                <p class="text-price mon-bold">Subtotal:</p>
                <p class="text-price-item mon-bold ml-2">
                  ${{ formatMoney(aOrders.dTotalProductPrice) }} USD
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--#endregion CONTENIDO DE ARTICULOS DEL PEDIDO DE VENTA -->

      <!-- <v-row>
        <v-col cols="12" md="12">
          <v-sheet
            v-if="loaderSkeleton"
            color="white lighten-4"
            style="
              border-radius: 9px !important;
              box-shadow: 0px 3px 6px #00000029;
            "
            class="pa-1 full-height-image-skeleton"
          >
            <v-skeleton-loader
              class="mx-auto"
              height="80px"
              type="list-item"
            ></v-skeleton-loader>
          </v-sheet>
          <div
            v-else
            class="
              container-card
              display-flex
              align-items-center
              height-100
              pa-5
            "
          >
            <p class="text-aprox mon-bold mr-5">
              {{ (selectLanguage == "sp"? 'Pago':'Payment') }}
            </p>
            <p class="text-status mon-semibold mr-5">{{ namePay }}</p>

            <v-spacer></v-spacer>
            <p class="text-total-order mon-regular mr-5">
              {{ texts.orderdetail.labelTotalOrder }}
            </p>
            <p class="text-total-money mon-semibold">
              ${{ formatMoney(aOrders.dTotalPrice) }} USD
            </p>
          </div>
        </v-col>
      </v-row> -->

      <!--#region CONTENIDO DE DESGLOSE DE COSTO Y MANEJO EN ALMACEN -->
      <v-row>
        <v-col cols="12" md="6">
          <div>
            <p class="text-title-card mon-bold">
              {{ texts.orderdetail.labelCostSend }}
            </p>
            <v-sheet v-if="loaderSkeleton" color="white lighten-4" style="border-radius: 9px !important;box-shadow: 0px 3px 6px #00000029;
              " class="pa-1">
              <v-skeleton-loader class="mx-auto" height="270px" type="list-item-three-line"></v-skeleton-loader>
            </v-sheet>
            <div v-else class="container-card">
              <div v-for="(item, index) in aOrders.SalesOrderFees" :key="index" class="
                  display-flex
                  align-items-center
                  border-bottom
                  pt-3
                  pb-3
                  pl-5
                  pr-5
                ">
                <!-- <p v-if="item.sFeeId === '069d0bb0-90d6-4073-ad4b-b3b754e549a9'" class="text-cost-mexico mon-medium">
                   {{ selectLanguage == 'sp' ? 'Subtotal de productos' : 'Product Subtotal' }}
                </p> -->
                <p class="text-cost-mexico mon-medium">
                  {{ item.sFeeName }}
                </p>
                <v-spacer></v-spacer>
                <p v-if="item.sFeeId === 'ac9cbe69-c251-48b5-8954-1c6e0d16d47b'"
                  class="text-cost-mexico-price mon-regular">
                  <span v-if="item.dTotal > 0">
                    ${{ formatMoney(item.dTotal) }} USD
                  </span>
                  <span v-else>
                    {{ selectLanguage == 'sp' ? 'No aplica' : ' Does not apply' }}
                  </span>
                </p>
                <p v-else-if="item.sFeeId === '64d6ff43-bc6d-4ded-bf62-0d11e05ef4b0'"
                  class="text-cost-mexico-price mon-regular">
                  <span v-if="item.dTotal > 0">
                    ${{ formatMoney(item.dTotal) }} USD
                  </span>
                  <span v-else>
                    {{ selectLanguage == 'sp' ? 'En espera del costo de flete ' : "Waiting for supplier's freight cost" }}
                  </span>
                </p>
                <p v-else class="text-cost-mexico-price mon-regular">
                  ${{ formatMoney(item.dTotal) }} USD
                </p>
              </div>
              <div class="display-flex align-items-center pt-3 pb-3 pl-5 pr-5">
                <p class="text-cost-mexico mon-medium">
                  {{ texts.orderdetail.labelFactureText }}
                </p>
                <v-spacer></v-spacer>
                <p v-if="shippingCost != null" class="text-link mon-regular" @click="downloadShippingCostFile">
                  {{ texts.orderdetail.labelDownloadFile }}
                  <v-icon color="#0971fb" size="16px"> mdi-download </v-icon>
                </p>
                <p v-else class="text-cost-mexico-price mon-regular">
                  {{ selectLanguage == 'sp' ? 'Aún no hay información' : 'No information yet' }}</p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <p class="text-title-card mon-bold">
              {{ texts.orderdetail.labelManejoWarehouse }}
            </p>
            <v-sheet v-if="loaderSkeleton" color="white lighten-4" style="border-radius: 9px !important;box-shadow: 0px 3px 6px #00000029;
              " class="pa-1">
              <v-skeleton-loader class="mx-auto" height="200px" type="list-item-three-line"></v-skeleton-loader>
            </v-sheet>
            <div v-else class="container-card">
              <div>
                <div class="
                    display-flex
                    align-items-center
                    border-bottom
                    pt-3
                    pb-3
                    pl-5
                    pr-5
                  ">
                  <p class="text-cost-mexico mon-medium">
                    {{ texts.orderdetail.labelsInOut.name }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="text-cost-mexico-price mon-regular">In&Out</p>
                </div>
                <div class="
                    display-flex
                    align-items-center
                    border-bottom
                    pt-3
                    pb-3
                    pl-5
                    pr-5
                  ">
                  <p class="text-cost-mexico mon-medium">
                    {{ texts.orderdetail.labelsInOut.numPallets }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="text-cost-mexico-price mon-regular">
                    {{ oInOut.dTotalPallets }}
                  </p>
                </div>
                <div class="
                    display-flex
                    align-items-center
                    border-bottom
                    pt-3
                    pb-3
                    pl-5
                    pr-5
                  ">
                  <p class="text-cost-mexico mon-medium">
                    {{ texts.orderdetail.labelsInOut.pricePallet }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="text-cost-mexico-price mon-regular">
                    <span>
                      ${{ formatMoney(oInOut.dInOutRateValue) }} USD
                    </span>
                  </p>
                </div>
                <div class="display-flex align-items-center pt-3 pb-3 pl-5 pr-5">
                  <p class="text-cost-mexico mon-bold">
                    {{ texts.orderdetail.labelsInOut.subtotal }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="text-total-price mon-bold">
                    ${{ formatMoney(oInOut.dTotalPrice) }} USD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>

      </v-row>
      <!--#endregion CONTENIDO DE DESGLOSE DE COSTO Y MANEJO EN ALMACEN -->

      <!--#region CONTENIDO DE PAGO Y TOTAL DE LA ORDEN -->
      <v-row>
        <v-col>
          <div class="content-shadow padding-lateral-max">
            <div class="display-flex align-items-center height-100">
              <div>
                <p class="text-label-payments mon-bold">
                  {{ selectLanguage == "sp" ? "Pago" : "Payment" }}
                </p>
                <p class="text-label-payments-item mon-regular">
                  {{ namePay }}
                </p>
              </div>
              <v-spacer />
              <div>
                <p class="text-label-payments mon-bold">
                  {{ texts.orderdetail.labelTotalOrder }}
                </p>
                <p class="text-label-payments-item mon-regular">
                  ${{ formatMoney(aOrders.dTotalPrice) }} USD
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--#endregion CONTENIDO DE PAGO Y TOTAL DE LA ORDEN -->

      <v-row>
        <v-col cols="12" md="6">
          <p class="text-title-card mon-bold">
            {{ texts.orderdetail.labelComprobantPay }}
          </p>
          <v-sheet v-if="loaderSkeleton" color="white lighten-4"
            style="border-radius: 9px !important; box-shadow: 0px 3px 6px #00000029;"
            class="pa-1 full-height-image-skeleton">
            <v-skeleton-loader class="mx-auto" height="270px" type="image"></v-skeleton-loader>
          </v-sheet>
          <div v-else>
            <div v-if="proofPayment != null" style="height: 230px; position: relative"
              class="container-card pa-5 display-flex align-items-center justify-content-center">
              <div class="text-center">
                <v-btn @click="deleteProofPaymentFile" class="button-delete-proof-payment" elevation="0">
                  <v-icon size="20px" color="#FFFFFF"> mdi-delete </v-icon>
                </v-btn>
                <v-icon color="#E3574C" size="150px">
                  mdi-file-document
                </v-icon>
                <p @click="downloadProoPayment" class="text-prof-payment-name mon-regular mt-1">
                  {{ proofPaymentName }}
                </p>
              </div>
            </div>
            <div class="container-card pa-5" v-else>
              <v-btn @click="$refs.inputUpload.click()" class="button-add-document mon-bold" elevation="0">
                <div>
                  <v-icon color="#B2B2B2" size="60px">
                    mdi-file-document
                  </v-icon>
                  <p class="text-button-document mon-semibold mt-2" v-html="texts.orderdetail.labelUpload" />
                </div>
                <input ref="inputUpload" style="display: none" type="file" size="60" accept=".doc, .docx, .pdf"
                  @change="uploadFilesWarehouse($event)" />
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <p class="text-title-card mon-bold">
            {{ texts.orderdetail.labelPaymentMethod }}
          </p>
          <div class="container-card pa-5 content-card-paymentmethod">
            <img v-if="
            oPaymentData.sPaymentMethodId ===
            '3d0c5ce8-2eaf-479a-a49a-8d2c4f48f387'" class=" img-paymentmethod" src="@/assets/images/CreditProplat.png"
              alt="" />
            <p v-else class="text-payment-card mon-bold mr-9">
              {{ oPaymentData.sName }}
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <p class="text-title-card mon-bold">
            {{ texts.orderdetail.labelWarehouse }}
          </p>
          <v-sheet v-if="loaderSkeleton" color="white lighten-4" style="
              border-radius: 9px !important;
              box-shadow: 0px 3px 6px #00000029;
            " class="pa-1">
            <v-skeleton-loader class="mx-auto" height="250px" type="card"></v-skeleton-loader>
          </v-sheet>
          <div v-else class="container-card pa-5">
            <v-row>
              <v-col cols="12" md="4">
                <div>
                  <div class="mb-9">
                    <p class="text-map-title mon-bold mb-1">
                      {{ texts.orderdetail.labelWarehouseSend }}
                    </p>
                    <p class="text-map-subtitle mon-regular">
                      {{ nameWarehouseEnv }}
                    </p>
                  </div>
                  <div>
                    <p class="text-map-title mon-bold mb-1">
                      {{ texts.orderdetail.labelWarehouseEnt }}
                    </p>
                    <p class="text-map-subtitle mon-regular">
                      {{ nameWarehouseEnt }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <div>
                  <GmapMap :center="{ lat: latOrigin, lng: lngOrigin }" :zoom="4" :options="mapOptions"
                    style="width: 100%; height: 350px">
                    <GmapMarker :position="{ lat: latOrigin, lng: lngOrigin }" />
                    <GmapMarker :position="{ lat: latDestination, lng: lngDestination }" />
                  </GmapMap>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" md="12">
          <p class="text-title-card mon-bold">
            {{ texts.orderdetail.labelSeg }}
          </p>
          <div class="container-card pa-5 seguimiento-panel">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel v-for="(item, index) in aOrders.aSalesOrderStatusRecord" :key="index">
                <v-expansion-panel-header>
                  <div class="
                      display-flex
                      align-items-center
                      justify-content-center
                      number-section
                    ">
                    {{ index + 1 }}
                  </div>
                  <p class="mon-medium text-title-step ml-7 mb-0">
                    {{ item.sSalesOrderStatusName }} -
                    <span class="mon-regular">{{ item.tCreatedAtView }}</span>
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="pb-0" cols="12" md="12">
                      <div>
                        <p class="text-description-seguimiento mon-medium">
                          {{ item.sDescription }}
                        </p>
                      </div>
                    </v-col>
                    <v-col v-if="item.aSalesOrderStatusRecordImages.length > 0" cols="12" md="12">
                      <div class="pt-2 pb-2 container-carousel">
                        <div class="
                            content-carousel-effect
                            display-flex
                            align-items-center
                          ">
                          <div class="mr-5" v-for="(
                              item_image, index_image
                            ) in item.aSalesOrderStatusRecordImages" :key="index_image">
                            <div @click="
                              openCarousel(
                                index_image,
                                item.aSalesOrderStatusRecordImages
                              )
                            " class="content-image-carousel cursor-pointer">
                              <img :src="item_image.oImages.md" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="currentStatus == 'e79ef080-50d8-4aa6-a198-58a491c8a772'">
        <v-col>
          <div class="border-bottom" />
        </v-col>
      </v-row>

      <v-row v-if="currentStatus == 'e79ef080-50d8-4aa6-a198-58a491c8a772'">
        <v-col>
          <div v-if="isResponsive" class="mb-9">
            <v-btn @click="finalizeOrder" :loading="dLodingFinalize" elevation="0" class="button-finalize mon-bold"
              style="width:100%;">
              {{ texts.orderdetail.buttonFinalizeOrder }}
            </v-btn>
            <v-btn @click="startDispute" elevation="0" class="button-start mon-regular mt-5" style="width:100%;">
              {{ texts.orderdetail.buttonStartDispute }}
            </v-btn>
          </div>
          <div v-else class="display-flex align-items-center justify-content-flex-end mb-9">
            <v-btn @click="startDispute" elevation="0" class="button-start mon-regular">
              {{ texts.orderdetail.buttonStartDispute }}
            </v-btn>
            <v-btn @click="finalizeOrder" :loading="dLodingFinalize" elevation="0"
              class="button-finalize mon-bold ml-4">
              {{ texts.orderdetail.buttonFinalizeOrder }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </div>
</template>

<script>
export default {
  name: "OrderDetailContentLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      aOrders: [],
      oInOut: {},
      oPaymentData: {},
      orderStatus: "",
      panel: 0,
      imageSelected: "",
      withoutImages: "",
      nameProvider: "",
      namePay: "",
      nameStatus: "",
      namePayType: "",
      nameWarehouseEnv: "",
      nameWarehouseEnt: "",
      orderOriginLocation: "",
      orderDestinationLocation: "",
      latOrigin: 0,
      lngOrigin: 0,
      latDestination: 0,
      lngDestination: 0,
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 5,
        minZoom: 3,
      },
      currentStatus: "",
      dLodingFinalize: false,
      salesOrderIdFileTypeId: "",
      loaderSkeleton: true,
      shippingCost: null,
      shippingCostName: "",
      shippingCostFile: null,
      proofPayment: null,
      proofPaymentName: "",
      proofPaymentFile: null,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getOrdersDetails();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    openCarousel: function (imgSelected, allImages) {
      this.$store.commit("setGallery", {
        active: true,
        imageSelected: imgSelected,
        imagesArr: allImages,
      });
    },
    downloadShippingCostFile: function () {
      window.open(this.shippingCostFile, "blank");
    },
    deleteProofPaymentFile: function () {
      let sales_order_id = this.aOrders.aSalesOrderGeneralFiles.filter((e) => {
        if (e.sSalesOrderFileTypeId == "27989780-cbb8-44a6-8be9-1792283dfb00") {
          return e;
        }
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const payload = {};

      DB.delete(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/files/${sales_order_id[0].sSalesOrderFileTypeId}`,
        config,
        payload
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    downloadProoPayment: function () {
      window.open(this.proofPaymentFile, "blank");
    },
    finalizeOrder: function () {
      this.$store.commit("setRateOrder", {
        active: true,
        purchaseOrderId: this.aOrders.oPurchaseOrder.sPurchaseOrderId,
        salesOrderId: this.$route.params.id,
        vendorName: this.aOrders.oVendorEnterprise.sName,
      });
    },
    formatDate: function (date) {
      var formating_date = MOMENT(date).format("MMM. DD, YYYY hh:mm A");
      return formating_date;
    },
    formatMoney: function (money) {
      //return number in format money
      return FORMAT_MONEY(money);
    },
    selectImage: function (e) {
      this.imageSelected = e.oImages.lg;
    },
    getOrdersDetails: function () {
      this.loaderSkeleton = true;

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.loaderSkeleton = false;
          let arr = response.data.results;

          this.aOrders = arr;
          this.oPaymentData = this.aOrders.oPaymentData;

          this.salesOrderIdFileTypeId =
            arr.aSalesOrderGeneralFiles[0].sSalesOrderFileTypeId;
          this.nameProvider = arr.oVendorEnterprise.sName;
          this.namePay = arr.oPaymentData.oStatus.sPaid;
          this.nameStatus = arr.oSalesOrderStatus.sName;
          this.namePayType = arr.oPaymentData.sName;
          this.nameWarehouseEnv = arr.oOriginWarehouse.sAddress;
          this.nameWarehouseEnt = arr.oDestinationWarehouse.sAddress;
          this.panel = arr.aSalesOrderStatusRecord.length - 1;
          this.latOrigin = arr.oOriginWarehouse.oCoordinates.dLatitude;
          this.lngOrigin = arr.oOriginWarehouse.oCoordinates.dLongitude;
          this.latDestination =
            arr.oDestinationWarehouse.oCoordinates.dLatitude;
          this.lngDestination =
            arr.oDestinationWarehouse.oCoordinates.dLongitude;
          this.currentStatus = arr.oSalesOrderStatus.sStatusId;

          this.shippingCost = arr.aSalesOrderGeneralFiles[0].sFileKey;
          this.shippingCostName =
            arr.aSalesOrderGeneralFiles[0].sSalesOrderFileTypeName;
          this.shippingCostFile = arr.aSalesOrderGeneralFiles[0].sUrlFile;
          this.proofPayment = arr.aSalesOrderGeneralFiles[1].sFileKey;
          this.proofPaymentName =
            arr.aSalesOrderGeneralFiles[1].sSalesOrderFileTypeName;
          this.proofPaymentFile = arr.aSalesOrderGeneralFiles[1].sUrlFile;

          this.oInOut = arr.oInOutFee;

          this.extractImageSelected();
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    extractImageSelected: function () {
      let img_selected = "";

      this.aOrders.aSalesOrderStatusRecord.forEach((element) => {
        if (element.aSalesOrderStatusRecordImages.length > 1) {
          img_selected = element.aSalesOrderStatusRecordImages[0].oImages.lg;
        }
      });

      this.imageSelected = img_selected;
    },
    startDispute: function () {
      this.$store.commit("setDialogCreateDispute", {
        active: true,
        arr: this.aOrders,
      });
    },
    // --- start: upload document --- //
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.uploadFiles(files[0]);
        });
      }
    },
    uploadFiles(file, message) {
      var form = new FormData();
      form.append("file", file);

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/files/${this.aOrders.aSalesOrderGeneralFiles[1].sSalesOrderFileTypeId}`,
        form,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => { });
    },
    // --- end: upload document --- //
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getOrdersDetails();
      }
    },
    refresh: function () {
      this.getOrdersDetails();
    },
  },
};
</script>

<style>
.v-data-table-header-mobile{
  display: none;
}
.text-order-label {
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  opacity: 1 !important;
  margin-bottom: 0px !important;
}
/* .table-article-order .v-data-table__wrapper table thead tr th {
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  opacity: 1 !important;
margin-bottom: 0px !important;
} */

.seguimiento-panel .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #000000;
}

.full-height-image-skeleton .v-skeleton-loader__image {
  height: 100% !important;
}
</style>

<style scoped>
.content-gray {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px 15px;
}

.padding-lateral-max {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.text-label-payments {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-label-payments-item {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price-item {
  font-size: 14px;
}

.text-price {
  font-size: 14px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

/* start: carousel images */
.content-carousel-effect {
  overflow-x: auto;
  overflow-y: hidden !important;
}

.content-carousel-effect::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content-carousel-effect {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.container-carousel {
  width: 100%;
}

.content-image-carousel {
  width: 90px;
  height: 90px;
}

.content-image-carousel img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-carousel img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}

/* end: carousel images */
.button-delete-proof-payment {
  min-width: 40px !important;
  height: 40px !important;
  background: #d8686a 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}

.text-prof-payment-name {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  margin-bottom: 0px;
}

.text-prof-payment-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button-show-dispute {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  background-color: transparent !important;
  text-transform: initial;
  padding: 0px 8px !important;
}

.text-order-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-product {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-warehouse-buyer {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.text-label {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-label-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-description-seguimiento {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #00000080;
  opacity: 1;
  margin-bottom: 0px;
}

.seguimiento-panel .v-expansion-panel-header {
  padding: 0px;
}

.seguimiento-panel .content-left-line {
  border-left: 1px solid #00000029;
  margin-left: -15px;
  padding: 0px 10px;
}

.seguimiento-panel .text-title-step {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.seguimiento-panel .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}

.seguimiento-panel .v-stepper--vertical {
  padding-bottom: 0px;
}

.number-section {
  background-color: black;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  position: absolute;
  margin-right: 10px;
}

.seguimiento-panel .v-expansion-panel::before {
  box-shadow: none !important;
}

.button-start {
  height: 40px !important;
  border: 1px solid #283c4d;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-finalize {
  height: 40px !important;
  background: transparent linear-gradient(256deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.seguimiento-panel .v-expansion-panel::before {
  box-shadow: none !important;
}

.text-payment-card {
  text-align: center;
  display: flex;
  font-size: 14px;
  letter-spacing: 0px;
  color: #40ae8b;
  opacity: 1;
  margin-bottom: 0px;
}

.text-payment-card-number {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 0.5;
  margin-bottom: 0px;
}

.text-map-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-map-subtitle {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-title-info {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-title-subinfo {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.subtext-product-text {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.button-add-document {
  background-color: #ffffff !important;
  width: 100%;
  height: 190px !important;
  border: 1px dashed #707070;
}

.text-button-document {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 1;
  text-transform: initial;
}

.text-link {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  margin-bottom: 0px;
}

.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-cost-mexico {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-cost-mexico-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.text-total-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  margin-bottom: 0px;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.text-title-card {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #414b54;
  opacity: 1;
  margin-bottom: 0px;
}

.text-aprox {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-aprox-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-total-order {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-total-money {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.text-folio {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-creation {
  /* text-align: right; */
  font-size: 13px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-status {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-status-result {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.content-card-paymentmethod {
  height: 230px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-paymentmethod {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 50%;
  object-fit: contain;
}

/* imagenes articulos */
.products-vendor .content-image {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}

.products-vendor .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .products-vendor .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}

/* imagenes seguimiento */
.content-fixed-image {
  width: 125px;
  max-height: 350px;
  overflow: auto;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-resize-image {
  width: calc(100% - 125px);
  height: 350px;
  padding: 10px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }

  .content-fixed-image {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .content-fixed-image .content-image {
    width: 130px;
    margin: 1px;
    padding: 4px;
  }

  .content-resize-image {
    width: 100% !important;
  }

}
</style>