<template>
  <div
    class="bg-color fixed-nav"
    :style="(fadeMenu) ? {zIndex: '1'} : {zIndex: '9'}"
  >
    <div
      @click="$store.commit('setFadeNotificationCart',false)"
      style="background-color: black;
        width: 100%;
        position: absolute;
        height: 100vh;
        z-index: 2;
        opacity: 0.2;
        cursor: pointer;
      "
      v-if="fadeNotificationCart"
    />
    <v-container
      fluid
      class="v-container-fluid"
      style="position: relative;"
    >
      <notification-cart-component />
      <v-row>
        <v-col
          class="pl-0"
          cols="12"
          xl="4"
          lg="4"
          md="3"
        >
          <div class="display-flex align-items-center height-100">
            <v-btn
              icon
              dark
              @click="openFadeMenu"
            >
              <v-icon
                size="18px"
                color="#FFFFFF"
              >
                mdi-menu
              </v-icon>
            </v-btn>
            <div class="ml-2 img-logo">
              <img
                @click="home"
                style="cursor: pointer;"
                src="@/assets/logos/logo-bg-black.svg"
                alt="proplat-logo-white"
              />
            </div>
            <v-spacer></v-spacer>
            <div
              v-if="isLogged"
              class="hidden-from-desktop display-flex align-items-center justify-content-flex-end ml-2"
            >
              <v-btn
                @click="redirectShoppingCart"
                dark
                icon
              >
                <v-badge
                  :content="NumberProductsCart"
                  :value="NumberProductsCart"
                  overlap
                  color="red"
                >
                  <v-icon
                    color="#ffffff"
                    size="21px"
                  >
                    mdi-cart-outline
                  </v-icon>
                </v-badge>
              </v-btn>
              <v-menu
                v-if="notifications.length > 0"
                content-class="menu-arrow"
                transition="slide-x-transition"
                bottom
                right
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">

                  <v-btn
                    class="ml-n1"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    dark
                  >
                    <v-badge
                      :content="countNotifications"
                      :value="countNotifications"
                      overlap
                      color="red"
                    >
                      <v-icon
                        color="#ffffff"
                        size="21px"
                      >
                        mdi-bell-outline
                      </v-icon>
                    </v-badge>
                  </v-btn>

                </template>

                <v-list
                  style="margin-top: -11px; overflow: auto;"
                  max-width="400px"
                  max-height="350px"
                  class="pa-0"
                >
                  <v-list-item
                    @click="markAsReadNotification(item.sNotificationId)"
                    class="pa-0"
                    style="min-height: 0px"
                    v-for="(item, index) in notifications"
                    :key="index"
                  >
                    <v-list-item-title>
                      <div :class="(item.bRead) ? 'no-view-not display-flex' : 'view-not display-flex'">
                        <div :class="(item.bRead) ? 'rounded-icon' : 'not-rounded-icon'">
                          <v-icon
                            v-if="item.bRead"
                            color="#fff"
                          >
                            mdi-shopping-outline
                          </v-icon>
                          <v-icon
                            v-else
                            color="#ffa77e"
                          >
                            mdi-shopping-outline
                          </v-icon>
                        </div>
                        <div class="ml-2">
                          <p class="text-menu-title mon-semibold">{{ item.sTitle }}</p>
                          <p class="text-menu-description mon-regular">{{ item.sMessage }}</p>
                          <p class="text-menu-date mon-regular mt-1">{{ item.created_at }}</p>
                        </div>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                v-else
                @click="withoutNotifications"
                icon
                dark
              >
                <v-icon
                  color="#ffffff"
                  size="21px"
                >
                  mdi-bell-outline
                </v-icon>
              </v-btn>
              <div
                @click="redirectProfile"
                class="circle-ext ml-1 cursor-pointer"
              >
                <div class="circle-int">
                  <p class="circle-text mon-regular">{{ sFullName }}</p>
                </div>
              </div>
            </div>
            <v-btn
              class="button-primary hidden-from-desktop ml-3"
              style="border: 1px solid #5b6067; border-radius: 100% !important;"
              width="25px"
              height="25px !important"
              icon
              dark
              @click="openMenu=!openMenu"
            >
              <v-icon
                size="23px"
                color="#5b6067"
              >
                {{
                  (openMenu)
                    ? 'mdi-close'
                    : 'mdi-chevron-down'
                }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          class="hidden-from-tablet"
          cols="12"
          xl="4"
          lg="4"
          md="6"
        >
          <div style="position: relative;">
            <div class="display-flex align-items-center">
              <input
                v-model="sSearch"
                class="input-search mon-regular"
                type="text"
                ref="sSearch"
                :placeholder="texts.input.searchText"
                onfocus="this.placeholder=''"
                :onblur="`this.placeholder='${texts.input.searchText}'`"
              >
              <v-btn
                class="button-search"
                @click="focusSearch"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
            <div>
              <search-global-component
                :sSearch="sSearch"
                :arrSearch="arrSearch"
                @clearSearch="clearSearch"
              />
            </div>
          </div>
        </v-col>
        <!-- is logged -->
        <v-col
          v-if="isLogged"
          class="hidden-from-tablet"
          cols="12"
          xl="4"
          lg="4"
          md="3"
        >
          <div class="display-flex align-items-center justify-content-flex-end">
            <v-btn
              @click="redirectShoppingCart"
              dark
              icon
            >
              <v-badge
                :content="NumberProductsCart"
                :value="NumberProductsCart"
                overlap
                color="red"
              >
                <v-icon
                  color="#ffffff"
                  size="21px"
                >
                  mdi-cart-outline
                </v-icon>
              </v-badge>
            </v-btn>
            <v-menu
              v-if="notifications.length > 0"
              content-class="menu-arrow2"
              transition="slide-x-transition"
              bottom
              right
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">

                <v-btn
                  class="ml-4"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  dark
                >
                  <v-badge
                    :content="countNotifications"
                    :value="countNotifications"
                    overlap
                    color="red"
                  >
                    <v-icon
                      color="#ffffff"
                      size="21px"
                    >
                      mdi-bell-outline
                    </v-icon>
                  </v-badge>
                </v-btn>

              </template>

              <v-list
                style="margin-top: -11px; overflow: auto;"
                max-width="400px"
                max-height="350px"
                class="pa-0"
              >
                <v-list-item
                  @click="markAsReadNotification(item.sNotificationId)"
                  class="pa-0 cursor-pointer"
                  style="min-height: 0px"
                  v-for="(item, index) in notifications"
                  :key="index"
                >
                  <v-list-item-title>
                    <div :class="(item.bRead) ? 'no-view-not display-flex' : 'view-not display-flex'">
                      <div :class="(item.bRead) ? 'rounded-icon' : 'not-rounded-icon'">
                        <v-icon
                          v-if="item.bRead"
                          color="#fff"
                        >
                          mdi-shopping-outline
                        </v-icon>
                        <v-icon
                          v-else
                          color="#ffa77e"
                        >
                          mdi-shopping-outline
                        </v-icon>
                      </div>
                      <div class="ml-2">
                        <p class="text-menu-title mon-semibold">{{ item.sTitle }}</p>
                        <p class="text-menu-description mon-regular">{{ item.sMessage }}</p>
                        <p class="text-menu-date mon-regular mt-1">{{ item.created_at }}</p>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-else
              @click="withoutNotifications"
              class="ml-4"
              icon
              dark
            >
              <v-icon
                color="#ffffff"
                size="21px"
              >
                mdi-bell-outline
              </v-icon>
            </v-btn>
            <div
              @click="redirectProfile"
              class="circle-ext ml-5 cursor-pointer"
            >
              <div class="circle-int">
                <p class="circle-text mon-regular">{{ sFullName }}</p>
              </div>
            </div>
          </div>
        </v-col>
        <!-- no logged -->
        <v-col
          v-else
          class="hidden-from-tablet"
          cols="12"
          xl="4"
          lg="4"
          md="3"
        >
          <div class="display-flex align-items-center justify-content-flex-end">
            <v-btn
              to="/login"
              class="button-login mon-regular"
            >
              {{ texts.buttons.loginText }}
            </v-btn>
            <v-btn
              to="/signin"
              class="button-register mon-regular ml-3"
            >
              {{ texts.buttons.signupText }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- execute check -->
    <div
      class="container-responsive-menu"
      v-if="openMenu"
      fluid
    >
      <div>
        <div style="position: relative;">
          <div class="display-flex align-items-center">
            <input
              v-model="sSearch"
              class="input-search mon-regular"
              type="text"
              ref="sSearch"
              :placeholder="texts.input.searchText"
              onfocus="this.placeholder=''"
              :onblur="`this.placeholder='${texts.input.searchText}'`"
            >
            <v-btn
              class="button-search"
              @click="focusSearch"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </div>
          <div>
            <search-global-component
              :sSearch="sSearch"
              :arrSearch="arrSearch"
              @clearSearch="clearSearch"
            />
          </div>
        </div>
        <div
          v-if="!isLogged"
          class="mt-3"
        >
          <v-btn
            to="/login"
            style="width: 100%"
            class="button-login mon-regular"
          >
            {{ texts.buttons.loginText }}
          </v-btn>
          <v-btn
            to="/signin"
            style="width: 100%"
            class="button-register mon-regular mt-2"
          >
            {{ texts.buttons.signupText }}
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-if="arrSearch.length > 0 || sSearch != ''"
      @click="clearSearch"
      style="
        background-color: #00000070;
        height: 100vh;
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 0px;
      "
    />
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      //VARIABLES
      openMenu: false,
      texts: "",
      sSearch: "",
      arrSearch: [],
      notifications: [],
      countNotifications: 0,
      NumberProductsCart: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.navbar[this.selectLanguage];
    if (this.$store.state.sUserId) {
      this.getNotifications();
      this.getNotificationsCount();
      this.getShoppingCart();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    withoutNotifications: function () {
      if (this.selectLanguage == "sp") {
        this.mixInfo("Aún no tienes notificaciones");
      } else {
        this.mixInfo("You still have no notifications");
      }
    },
    redirectProfile: function () {
      this.$router
        .push({
          name: "Profile",
          params: {
            num: 1,
          },
        })
        .catch(() => {});
    },
    getShoppingCart: function () {
      const payload = {};
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
        config,
        payload
      )
        .then((response) => {
          this.NumberProductsCart = response.data.iTotal;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "YYYY/MM/DD");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.openMenu = false;
      }
    },
    focusSearch: function () {
      this.$refs.sSearch.focus();
    },
    openFadeMenu: function () {
      this.$store.commit("setFadeMenu", true);
    },
    home: function () {
      this.$router.push("/home").catch(() => {});
    },
    redirectShoppingCart: function () {
      this.$router.push("/shopping-cart").catch(() => {});
    },
    clearSearch: function () {
      this.sSearch = "";
      this.arrSearch = [];
    },
    allSearching: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {},
        params: {
          sSearch: this.sSearch,
        },
      })
        .then((response) => {
          this.arrSearch = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getNotifications: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          let arr = response.data.results.filter((e) => {
            return e.bRead == false;
          });
          this.notifications = arr;
        })
        .catch((error) => {
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    getNotificationsCount: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications/count`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.countNotifications = response.data.results.iUnread;
        })
        .catch((error) => {
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    markAsReadNotification: function (id) {
      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          bRead: true,
        };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications/${id}`,
        payload,
        config
      )
        .then((response) => {
          this.getNotifications();
          this.getNotificationsCount();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    isLogged: function () {
      return this.$store.state.isLogged;
    },
    fadeMenu: function () {
      return this.$store.state.fadeMenu;
    },
    sFullName: function () {
      var text = "";
      if (this.$store.state.sFullName.split(" ").length == 4) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[2].charAt(0);
      } else if (this.$store.state.sFullName.split(" ").length == 3) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[1].charAt(0);
      } else if (this.$store.state.sFullName.split(" ").length == 2) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[1].charAt(0);
      }
      return text;
    },
    fadeNotificationCart: function () {
      return this.$store.state.fadeNotificationCart;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.navbar[this.selectLanguage];
        if (this.$store.state.sUserId) {
          this.getNotifications();
          this.getNotificationsCount();
          this.getShoppingCart();
        }
      }
    },
    sSearch: lodash.debounce(function (val) {
      if (this.sSearch !== "") {
        this.allSearching();
      }
    }, 200),
    refresh: function () {
      this.getShoppingCart();
    },
  },
};
</script>

<style scoped>
.cursor-pointer:hover {
  cursor: pointer;
}

.menu-arrow {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}

.menu-arrow::before {
  position: absolute;
  content: "";
  top: 0;
  right: 72px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}

.menu-arrow2 {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}

.menu-arrow2::before {
  position: absolute;
  content: "";
  top: 0;
  right: 102px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}

.view-not {
  background-color: #fff5f0;
  padding: 10px;
}

.no-view-not {
  background-color: #ffffff;
  padding: 10px;
}

.text-menu-title {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.text-menu-description {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  white-space: pre-wrap;
  width: 220px;
}

.text-menu-date {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ff8181;
  opacity: 1;
  margin-bottom: 0px;
}

.rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent linear-gradient(180deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 1px solid #ffa77e;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-ext {
  background-color: #151d27;
  border: 1px solid #ff8181;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-int {
  background: transparent linear-gradient(225deg, #ffa77e 0%, #ff8181 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 11px 11px 0px;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-text {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #fff;
  margin-bottom: 0px;
}

.user-info {
  background-color: #151d27;
  color: #fffc81;
  border-radius: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #fffc81;
}

.height-100 {
  height: 100%;
}

.fixed-nav {
  position: fixed;
  width: 100%;
  z-index: 99999;
  margin-top: 0px;
}

.container-responsive-menu {
  background-color: #151d27;
  position: absolute;
  width: 100%;
  z-index: 99999;
  padding: 25px 15px;
}

.input-search {
  width: 100%;
  height: 40px;
  background: #1f3042 0% 0% no-repeat padding-box;
  border: 1px solid #3b4c58;
  border-radius: 13px 0px 0px 13px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
}

.input-search::placeholder {
  color: #ffffff;
  font-size: 14px;
}

.input-search:focus-visible {
  outline: none;
}

.button-search {
  min-width: 48px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 13px 13px 0px;
  opacity: 1;
}

.img-logo {
  width: 130px;
  height: 25px;
}

.img-logo img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .img-logo img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.bg-color {
  background: #151d27 0% 0% no-repeat padding-box;
  opacity: 1;
}

.button-login {
  width: 120px;
  height: 40px !important;
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
  background-color: transparent !important;
  font-size: 14px !important;
  text-transform: initial;
  padding: 0px 15px;
}

.button-register {
  width: 120px;
  height: 40px !important;
  background: transparent linear-gradient(256deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d !important;
  font-size: 14px !important;
  text-transform: initial;
  padding: 0px 15px;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}

@media (min-width: 961px) {
  .hidden-from-desktop {
    display: none;
  }
}
</style>