<template>
  <div>
    <div v-if="products.length > 0">
      <div
        v-for="(item, index) in products"
        :key="index"
        class="container-card mb-9"
      >
        <v-container fluid class="v-container-fluid ">
          <!-- <shopping-cart-searchbar-layout :texts="texts" :products="products"></shopping-cart-searchbar-layout> -->
          <!-- <shopping-cart-desktop-ui-layout v-if="!isTablet" :texts="texts" :products="products"
          v-on:validateChange="validateChange($event)" />
        <shopping-cart-tablet-ui-layout v-else :texts="texts" :products="products"
          v-on:validateChange="validateChange($event)" /> -->
          <div class="contain-gral">
            <!-- <v-container fluid class="v-container-fluid"> -->
            <v-row>
              <div class="content-image">
                <img v-if="item.sLogoKey !== ''" :src="item.sLogoKey" alt="" />
                <img
                  v-else
                  src="@/assets/images/placeholder-image.jpeg"
                  alt=""
                />
              </div>
              <div class="content-name-supplier ">
                <p class="text-module mon-bold text-name-supplier">
                  {{ item.sName }}
                </p>
              </div>
              <v-spacer />
            </v-row>
            <v-row class="pt-0 hidden-component">
              <v-col class="pt-0">
                <div class="border-bottom" />
              </v-col>
            </v-row>
            <v-row class="pb-0 hidden-component">
              <v-col class="pb-0" cols="12" sm="5">
                <div>
                  <p class="text-header mon-bold text-left">
                    {{ texts.shoppingcart.textHeaderProduct }}
                  </p>
                </div>
              </v-col>
              <v-col class="pb-0" cols="12" sm="2">
                <div>
                  <p class="text-header mon-bold ">
                    {{ texts.shoppingcart.textHeaderQuantity }}
                  </p>
                </div>
              </v-col>
              <v-col class="pb-0" cols="12" sm="3">
                <div class="text-start">
                  <p class="text-header mon-bold text-UnitPrice">
                    {{ texts.shoppingcart.textHeaderUnitPrice }}
                  </p>
                </div>
              </v-col>
              <v-col class="pb-0" cols="12" sm="2">
                <div>
                  <p class="text-header mon-bold text-right">
                    {{ texts.shoppingcart.textHeaderTotal }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 hidden-component">
              <v-col class="pt-0">
                <div class="border-bottom" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  v-for="(item_product, index_product) in item.aProducts"
                  :key="index_product"
                  class="card-content mb-5"
                >
                  <v-row>
                    <v-col cols="12" sm="5">
                      <div>
                        <div class="display-flex">
                          <div class="content-image-product">
                            <img
                              v-if="item_product.sImageUrl !== ''"
                              :src="item_product.sImageUrl"
                              alt=""
                            />
                            <img
                              v-else
                              src="@/assets/images/placeholder-image.jpeg"
                              alt=""
                            />
                          </div>
                          <div class="ml-2">
                            <div class="mb-2">
                              <p class="text-product-name mon-bold">
                                {{ item_product.sProductName }}
                              </p>
                            </div>
                            <div class="display-flex mb-1">
                              <v-icon color="#b2b2b2" size="16px">
                                mdi-account
                              </v-icon>
                              <p class="text-provider-name mon-regular">
                                {{ item.sName }}
                              </p>
                            </div>
                            <div class="display-flex">
                              <v-icon color="#b2b2b2" size="16px">
                                mdi-map-marker
                              </v-icon>
                              <p class="text-provider-location mon-regular">
                                {{ item_product.sWarehouseCityAndState }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="2">
                      <div class="height-100">
                        <div
                          class="display-flex align-items-center justify-content-flex-start height-100"
                        >
                          <div style="width: 100%;">
                            <input
                              v-model="item_product.dQuantity"
                              class="input-amount"
                              type="text"
                              :ref="item.sProductId"
                              @keypress="soloNumbers"
                              @keyup="
                                ajustAmount(
                                  item_product.sProductId,
                                  item.originQuantity,
                                  item_product.dQuantity
                                )
                              "
                              @blur="noEmpty(item_product.sProductId)"
                              :min="1"
                              maxlength="6"
                            />
                            <v-btn
                              v-if="
                                item_product.dQuantityOrigin !=
                                  item_product.dQuantity
                              "
                              class="button-modify mon-medium"
                              elevation="0"
                              @click="
                                modifyQuantity(
                                  item_product.sProductId,
                                  item_product.dQuantity,
                                  item_product.dMinAmountAllowed,
                                  item_product.dInventoryQuantity
                                )
                              "
                            >
                              {{ texts.shoppingcart.textButtonModify }}
                            </v-btn>
                            <p class="text-max-boxes mon-regular">
                              Max:
                              <strong>{{
                                item_product.dInventoryQuantity
                              }}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <div
                        class="display-flex align-items-center justify-content-flex-start height-100"
                      >
                        <p class="text-unit-price mon-regular">
                          ${{ formatMoney(item_product.dUnitPrice) }} USD
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <div
                        class="display-flex align-items-center justify-content-flex-end height-100"
                      >
                        <p class="text-total-price mon-bold mr-4">
                          ${{ formatMoney(item_product.dTotalPrice) }} USD
                        </p>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="setDialogDelete(item_product.sProductId)"
                            >
                              <v-icon color="#000" size="25px">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </template>
                          <span class="mon-regular">{{
                            texts.shoppingcart.buttonRemove
                          }}</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <!-- </v-container> -->
          </div>
          <v-row class="mb-5 contain-gral">
            <v-col>
              <div
                class="display-flex align-items-center justify-content-flex-end"
              >
                <div>
                  <div
                    class="
                  display-flex
                  alig-items-center
                  justify-content-flex-end
                  mt-1
                "
                  >
                    <p class="text-subtotal mon-regular">
                      {{ texts.shoppingcart.textSubtotal }}
                    </p>
                    <p class="text-subtotal mon-bold ml-3">
                      ${{ formatMoney(item.dTotalPrice) }} USD
                    </p>
                  </div>
                  <div
                    class="
                  display-flex
                  alig-items-center
                  justify-content-flex-end
                  mb-1
                "
                  >
                    <p class="text-terms">{{ texts.shoppingcart.textTerms }}</p>
                  </div>
                  <!-- <div class="content-keep-shopping-from-this-supplier">
                    <a
                      text
                      @click="redirectToVendor()"
                      class="text-keep-shopping-from-this-supplier"
                      :href="'/vendor/detail/' + item.sProviderId"
                    >
                      {{ texts.shoppingcart.textKeepShoppingFromThisSupplier }}
                    </a>
                  </div> -->
                  <div class="content-pallets-out-of">
                    <p
                      class="txt-pallets-out-of"
                      :class="
                        26 - parseInt(item.dTotalPallets) <= 25
                          ? 'text-red'
                          : 'text-green'
                      "
                    >
                      {{ 26 - parseInt(item.dTotalPallets) }}
                      {{ texts.shoppingcart.textPalletsOutOf }} 26
                      {{ texts.shoppingcart.textPalletsTotals }}
                      <!-- {{item}} -->
                    </p>
                    <span
                      :class="
                        26 - parseInt(item.dTotalPallets) <= 25
                          ? 'text-red'
                          : 'text-green'
                      "
                      >(</span
                    >
                    <a
                      text
                      @click="redirectToVendor()"
                      class="text-keep-shopping-from-this-supplier"
                      :href="'/vendor/detail/' + item.sProviderId"
                    >
                      {{
                        texts.shoppingcart.textKeepShoppingFromThisSupplier
                      }} </a
                    ><span
                      :class="
                        26 - parseInt(item.dTotalPallets) <= 25
                          ? 'text-red'
                          : 'text-green'
                      "
                      >)</span
                    >
                  </div>

                  <div
                    class="
                  display-flex
                  alig-items-center
                  justify-content-flex-end
                  mt-4
                "
                  >
                    <div class="btn-payment">
                      <v-btn
                        @click="redirectPaymentProcess(item)"
                        class="button-yellow mon-bold"
                        elevation="0"
                        :disabled="disabledButton"
                        :loading="bLoadingRedirectPaymentProcess"
                      >
                        {{ texts.shoppingcart.textButtonPayment }}
                      </v-btn>
                      <!-- <div class="display-flex align-items-center mt-5">
                    <p
                      @click="redirectArticles()"
                      class="text-link mon-semibold"
                    >
                      {{ texts.shoppingcart.seguir }}
                    </p>
                    <v-icon
                      color="#0071ff"
                      size="14px"
                    >mdi-arrow-right</v-icon>
                  </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="products.length > 0" style="height: 70px" /> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <div
            class="
              empty-content
              display-flex
              align-items-center
              justify-content-center
            "
          >
            <div>
              <div class="display-flex justify-content-center">
                <img
                  width="170px"
                  src="@/assets/icons/empty.gif"
                  alt="proplat-image"
                />
              </div>
              <p
                class="text-empty mon-regular mb-9"
                v-html="texts.shoppingcart.textEmptyContent"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShoppingCartContent1Layout",
  props: {
    texts: {
      type: Object,
    },
    // products: {
    //   type: Array
    // }
  },
  data() {
    return {
      //VARIABLES
      amount: 1,
      products: [],
      disabledButton: false,
      isTablet: false,
      bLoadingRedirectPaymentProcess: false,
      providers: [],
      checkItems: {},
    };
  },
  beforeMount() {
    this.getShoppingCart();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    redirectToVendor() {
      this.$store.commit("setCommingFromCart", true);
    },
    getShoppingCart: function() {
      const payload = {};
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
        config,
        payload
      )
        .then((response) => {
          this.products = response.data.results.map((e) => {
            return {
              ...e,
              originQuantity: e.aProducts[0].dQuantity,
              aProducts: e.aProducts.map((eProduct) => {
                return {
                  ...eProduct,
                  dQuantityOrigin: eProduct.dQuantity,
                };
              }),
              dTotalPallets: e.aProducts
                .map((e) => {
                  return e.dPallets;
                })
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue
                ),
            };
          });

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // seccion pegada
    minAmount: function(id) {
      if (this.$refs[id][0].value > 1) {
        this.$refs[id][0].value--;
      }
    },
    maxAmount: function(id) {
      if (this.$refs[id][0].value < 100) {
        this.$refs[id][0].value++;
      }
    },
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    formatMoney(money) {
      //return number in format money
      return FORMAT_MONEY(money);
    },
    ajustAmount: function(id, oldVal, newVal) {
      if (this.$refs[id][0].value == "00") {
        this.$refs[id][0].value = 1;
      } else if (this.$refs[id][0].value == "0") {
        this.$refs[id][0].value = 1;
      }

      if (oldVal != newVal) {
        this.$emit("validateChange", true);
      } else {
        this.$emit("validateChange", false);
      }
    },
    noEmpty: function(id) {
      if (this.$refs[id][0].value == "") {
        this.$refs[id][0].value = 1;
      }
    },
    modifyQuantity: function(
      id,
      dQuantity,
      dMinAmountAllowed,
      dInventoryQuantity
    ) {
      if (Number(dQuantity) < dMinAmountAllowed) {
        this.mixError(
          `${this.texts.shoppingcart.textAmountGreaterThan} ${dMinAmountAllowed} `,
          0
        );
      } else if (Number(dQuantity) > dInventoryQuantity) {
        this.mixError(
          `${this.texts.shoppingcart.textAmountLessThan} ${dInventoryQuantity}`,
          0
        );
      } else {
        const payload = { dQuantity: dQuantity };
        const config = {
          headers: { Authorization: `Bearer ${this.$store.state.sToken}` },
        };
        DB.put(
          `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart/${id}`,
          payload,
          config
        )
          .then((response) => {
            this.mixSuccess(response.data.message);
            this.$emit("validateChange", false);
            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    setDialogDelete: function(id) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.shoppingcart.delete.textTitle,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart/${id}`,
        redirect: "",
      });
    },
    // fin de seccion añadida
    redirectArticles: function() {
      this.$router.push({
        name: "Article",
        params: {
          idCategory: "all",
          idSubcategory: "b87bbcf26818fb2e6262fffeab402f03",
        },
      });
    },
    redirectPaymentProcess: function(item) {
      // this.bLoadingRedirectPaymentProcess = true;

      this.$store
        .dispatch("setVerifiedEnterprise")
        .then((resp) => {
          this.$store
            .dispatch("getVerifyShoppingCart", item.sProviderId)
            .then((resp) => {
              this.$store
                .dispatch("setFillCheckoutGlobal")
                .then((resp) => {
                  let aProviderTemp = resp.filter(
                    (e) => e.sProviderId === item.sProviderId
                  );
                  if (aProviderTemp.length > 0) {
                    this.checkItems = aProviderTemp[0];
                    if (resp) {
                      DB.get(
                        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
                        {
                          headers: {
                            Authorization: `Bearer ${this.$store.state.sToken}`,
                          },
                          params: {
                            iPageNumber: this.iCurrentPage,
                            iItemsPerPage: 12,
                          },
                        }
                      )
                        .then((response) => {
                          this.bLoadingRedirectPaymentProcess = false;

                          let arr_size = response.data.results.length;

                          if (arr_size > 0) {
                            this.$router.push({
                              name: "PaymentProcess",
                            });
                            this.$store.commit(
                              "updateCheckout",
                              this.checkItems
                            );
                          } else {
                            this.mixWarning(
                              this.texts.shoppingcart.emptyWarehouse
                            );
                          }
                        })
                        .catch((error) => {
                          this.bLoadingRedirectPaymentProcess = false;

                          this.mixError(
                            error.response.data.message,
                            error.response.status
                          );
                        });
                    } else {
                      this.mixError(
                        this.selectLanguage === "sp"
                          ? "Empresa compradora no ha sido verificada, por favor contacte con su administrador."
                          : "Customer enterprise has not been verified, please contact to administrator."
                      );
                      this.bLoadingRedirectPaymentProcess = false;
                    }
                  } else {
                    this.mixError(this.texts.shoppingcart.textNoProvider);
                    this.$store.commit("refresher", true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((error) => {
              this.$store.commit("refresher", true);
              this.mixError(error.response.data.message,0)
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleResize: function() {
      if (window.innerWidth <= 600) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    },
    validateChange: function(evt) {
      this.disabledButton = evt;
    },
    sumPrices: function() {
      let sum = 0;
      this.products.aProducts.forEach((element) => {
        sum = sum + Number(element.aProducts[0].dTotalPrice);
      });
      return FORMAT_MONEY(sum);
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function() {
      this.getShoppingCart();
    },
  },
};
</script>

<style scoped>
.content-name-supplier {
  display: flex;
  align-items: center;
}

.text-name-supplier {
  margin-bottom: 0px;
}

.content-keep-shopping-from-this-supplier {
  text-align: end;
  margin-left: 10px;
  margin-right: 0px;
}

.text-keep-shopping-from-this-supplier {
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 0px;
  color: #1976d2;
  cursor: pointer;
  text-decoration: underline;
}

.content-pallets-out-of {
  display: flex;
  align-items: center;
  text-align: end;
  font-size: 14px;
}

.txt-pallets-out-of {
  margin-bottom: 0px;
  font-size: 14px;
}

.text-red {
  color: rgb(255, 0, 0) !important;
}

.text-green {
  color: green;
}

.text-link {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 1;
  margin-bottom: 0px;
}

.contain-gral {
  margin: 0px -40px;
}

.text-UnitPrice {
  /* margin-left: 75px; */
}

.text-left {
  padding-left: 115px;
}

.text-right {
  padding-right: 70px;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  height: auto;
  margin-left: 5%;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button-refresh {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  height: 40px !important;
  margin-left: -1px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e5e5e5;
}

.width-100 {
  width: 100%;
}

.text-empty {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}

.empty-content {
  width: 100%;
  height: 430px;
}

.height-100 {
  height: 100%;
}

.button-remove {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #d8686a;
  opacity: 1;
  padding: 0px !important;
  text-transform: initial;
}

.text-amount {
  text-align: right;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-max {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.input-amount {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  opacity: 1;
  height: 40px;
  font-size: 14px;
  width: 70px;
  outline: none;
  text-align: center;
  padding: 5px;
}

/* Chrome, Safari, Edge, Opera */
.input-amount::-webkit-outer-spin-button,
.input-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-amount[type="number"] {
  -moz-appearance: textfield;
}

.text-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.text-name-market {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title-product {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.content-image {
  width: 100%;
  height: 150px;
}

/* .content-image-product {
  width: 100%;
  height: 150px;
} */
.content-image-product img {
  width: 90px;
  height: 80px;
  border-radius: 10px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 15px;
  }
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 15px;
}

.text-terms {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 0px;
}

.text-subtotal {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  text-transform: initial;
  text-align: right;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.separator-line {
  border: 1px solid #cecece;
}

.show {
  display: none;
}

@media (max-width: 960px) {
  .remove-top-bottom {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    /* justify-content: center; */
  }

  .contain-gral {
    margin: 0px 10px;
  }

  .hidden-content {
    display: none;
  }

  .centter-medium,
  .align-flex-end {
    justify-content: flex-end;
  }

  .show {
    display: block;
  }
}

/* seccion añadida */
.button-modify {
  background: transparent linear-gradient(263deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  font-size: 12px;
  text-transform: initial;
  height: 30px !important;
}

.text-total-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-max-boxes {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 0px;
}

.text-unit-price {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.button-refresh {
  background: #ffffff !important;
  height: 40px !important;
  min-width: 40px !important;
  margin-left: -1px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e5e5e5;
  border-radius: 0px 10px 10px 0px;
}

/* .input-amount {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  opacity: 1;
  height: 40px;
  font-size: 14px;
  width: 70px;
  outline: none;
  text-align: center;
  padding: 5px;
} */

/* Chrome, Safari, Edge, Opera */
/* .input-amount::-webkit-outer-spin-button,
.input-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* .input-amount[type="number"] {
  -moz-appearance: textfield;
}  */

.text-product-name {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-name {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.width-fixed {
  width: 110px;
}

.width-relative {
  width: calc(100% - 110px);
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
}

.content-image {
  width: 100px;
  height: 100px;
}

.content-image img {
  width: 100%;
  height: auto;
}

.v-ml {
  margin-left: 200px;
}

.text-module {
  font-size: 22px;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.border-bottom {
  border-bottom: 1px solid #d5d5d5;
}

.text-header {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.content-image {
  width: 50px;
  height: 50px;
  margin: 20px;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 10px;
  }
}

/*#region XS */
@media (max-width: 599px) {
  .hidden-component {
    display: none;
  }

  .text-max-boxes {
    text-align: center;
  }

  .button-yellow {
    width: 100%;
  }

  .btn-payment {
    width: 100%;
  }

  .text-module {
    font-size: 16px;
  }

  .input-amount {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .text-UnitPrice {
    margin-left: 0px;
  }

  .text-header {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 5px;
  }

  .text-product-name {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0px;
  }

  .text-total-price {
    text-align: right;
    font-size: 13px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .text-UnitPrice {
    margin-left: 0px;
    margin-right: 30px;
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }

  .text-UnitPrice {
    margin-left: 0px;
  }

  .text-left {
    padding-left: 115px;
  }

  .text-right {
    padding-right: 70px;
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }

  .text-UnitPrice {
    margin-left: 0px;
  }

  .text-left {
    padding-left: 115px;
  }

  .text-right {
    padding-right: 70px;
  }
}

/*#endregion XL */
/* fin de seccion añadida */
</style>
