<template>
  <div>
    <v-container class="v-container-fluid" fluid>
      <v-row>
        <v-col cols="12" md="12">
          <div style="max-height: 350px; overflow: auto; margin-bottom: 15px; padding: 5px;">
            <div v-for="(item, index) in warehouses" :key="index"
              @click="radioGroup == item.sCustomerWarehouseId, selectWarehouse(item)"
              class="content-card display-flex align-items-center"
              :style="(radioGroup == item.sCustomerWarehouseId) ? { border: '2px solid #FFA77E' } : { border: '2px solid #FFFFFF' }">
              <div class="width-100">
                <p class="text-title mon-medium mb-3">{{ item.oWarehouse.sName }}</p>
                <div class="display-flex align-items-flex-start width-90">
                  <v-icon color="#00000040" size="16px">
                    mdi-map-marker
                  </v-icon>
                  <p class="text-location mon-regular ml-1">
                    {{ item.oLocation.sAddress }} {{ item.oLocation.sCity }}
                  </p>
                </div>
                <div class="display-flex align-items-center width-90">
                  <v-icon color="#00000040" size="16px">
                    mdi-phone
                  </v-icon>
                  <p class="text-location mon-regular ml-1">{{ item.oWarehouse.sPhoneNumber }}</p>
                </div>
              </div>
              <div class="content-radio-button">
                <v-radio-group v-model="radioGroup">
                  <v-radio color="#FFA77E" :value="item.sCustomerWarehouseId" @change="selectWarehouse(item)"></v-radio>
                </v-radio-group>
              </div>
            </div>

            <div v-for="(item_border, index_border) in arrBorderWarehouses" :key="index_border"
              @click="radioGroup = item_border.sBorderWarehouseId, selectWarehouse(item_border)"
              class="content-card display-flex align-items-center"
              :style="(radioGroup == item_border.sBorderWarehouseId) ? { border: '2px solid #FFA77E' } : { border: '2px solid #FFFFFF' }">
              <div class="width-100">
                <p class="text-title mon-medium mb-3">{{ item_border.oWarehouse.sName }}</p>
                <div class="display-flex align-items-flex-start width-90">
                  <v-icon color="#00000040" size="16px">
                    mdi-map-marker
                  </v-icon>
                  <p class="text-location mon-regular ml-1">
                    {{ item_border.oLocation.sAddress }} {{ item_border.oLocation.sCity }}
                  </p>
                </div>
                <div class="display-flex align-items-center width-90">
                  <v-icon color="#00000040" size="16px">
                    mdi-phone
                  </v-icon>
                  <p class="text-location mon-regular ml-1">{{ item_border.oWarehouse.sPhoneNumber }}</p>
                </div>
              </div>
              <div class="content-radio-button">
                <v-radio-group v-model="radioGroup">
                  <v-radio color="#FFA77E" :value="item_border.sBorderWarehouseId"
                    @change="selectWarehouse(item_border)"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
          <div style="border-radius: 9px; margin-top: 20px">
            <p class="text-location-address mon-medium">{{ texts.paymentsprocess.textLocationWarehose }}</p>
            <GmapMap :center="center" :zoom="4" :options="mapOptions" style="width:100%;  height: 350px;">
              <GmapMarker :position="{ lat: latSelected, lng: lngSelected }" />
            </GmapMap>
          </div>
          <div class="content-btns-warehouse-shopping-card">
            <span class="content-back-to-shopping-card-warehouse">
              <v-icon color="#0089FF" size="20px">
                mdi-chevron-left
              </v-icon>
              <span class="text-return-cart mon-regular" @click="$router.push({ name: 'ShoppingCart' })">
                {{ texts.paymentsprocess.returnCart }}
              </span>
            </span>
            <v-spacer></v-spacer>
            <v-btn elevation="0" :disabled="!validateForm" class="button-next mon-bold btn-continue-warehouse"
              @click="nextStep(2)">
              {{ texts.paymentsprocess.buttonContinue }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PaymentProcessWarehouseLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      warehouses: [],
      center: {
        lat: 30.09024,
        lng: -100.712891,
      },
      radioGroup: null,
      warehouseId: "",
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 12,
        minZoom: 3,
      },
      latSelected: 0,
      lngSelected: 0,
      arrBorderWarehouses: [],
    };
  },
  beforeMount() {
    // this.getWarehouses();
    this.getBorderWarehouses();
  },
  methods: {
    nextStep: function (e) {
      this.$emit("sendParam", e);
    },
    selectWarehouse: function (e) {
      this.latSelected = e.oLocation.dLatitude;
      this.lngSelected = e.oLocation.dLongitude;
      this.warehouseId = e
      this.$emit("sendParamWarehouse", e);
      this.$emit("sendVendor", this.ocheckout.sProviderId)
    },
    getBorderWarehouses: function () {
      //post credentials and get access token from laravel backend
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/1/warehouses`,
        config
      )
        .then((response) => {
          this.arrBorderWarehouses = response.data.results;

        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getWarehouses: function () {
      //post credentials and get access token from laravel backend
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        config
      )
        .then((response) => {
          this.warehouses = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sFullName: function (e) {
      var matches = e.match(/\b(\w)/g);
      var text = matches.join(""); // JSON
      return text;
    },
  },
  computed: {
    ocheckout() {
      return this.$store.state.ocheckout;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return this.radioGroup !== null;
    },
  },
  watch: {
    radioGroup() {

    }
  },
};
</script>

<style scoped>
.button-next {
  height: 40px !important;
  background: transparent linear-gradient(264deg, #ffc431 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d !important;
  opacity: 1;
  text-transform: initial;
}

.button-next:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.text-return-cart {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0089ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-return-cart:hover {
  text-decoration: underline;
  cursor: pointer;
}

.content-radio-button {
  position: absolute;
  right: 10px;
}

.text-location-address {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.text-title {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #00000050;
  margin-bottom: 0px;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 15px;
  position: relative; */
}

.content-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8bbbdf 0% 0% no-repeat padding-box;
  border-radius: 19px;
  text-align: center;
  font-size: 35px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-btns-warehouse-shopping-card {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-warehouse-shopping-card {
    display: block;
    margin-top: 15px;
    align-items: center;
  }
  
  .content-back-to-shopping-card-warehouse{
    margin-bottom: 10px !important;
  }
  .btn-continue-warehouse{
    margin-top: 10px;
    width: 100% !important;
  }

}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>