const texts = {
  en: {
    orderdetail: {
      labelsInOut: {
        name: "Name",
        numPallets: "Number of pallets",
        pricePallet: "Price per pallet",
        subtotal: "Subtotal",
      },
      buttonShowDispute: "See dispute",
      sectionArticle: {
        text1: "Item",
        text2: "Requested quantity",
        // estaba en price by unity
        text3: "Price per unit",
        text4: "Final subtotal",
      },
      aHeaderArticle: [
        {
          text: "Item",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "sProductName",
        },
        {
          text: "Requested quantity",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "dDeliveredQuantity",
        },
        {
          text: "Price per unit",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "dDeliveredUnitPrice",
        },
        {
          text: "Final subtotal",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "dFinalPrice",
        },
      ],
      sectionFollow: {
        textTitle: "Follow-up",
        text1: "Confirmed order",
        text2: "In preparation",
        text3: "In transit",
        text4: "In the process of border crossing",
        text5: "The crossover is finalized.",
        text6: "Warehouse border",
        text7: "Accepted at border warehouse",
        text8: "Transferred",
        text9: "On delivery route",
        text10: "Delivered",
        text11: "Finished",
      },
      buttonStartDispute: "Make a dispute",
      buttonFinalizeOrder: "Finalize order",
      buttonReturn: "Back",
      labelCreationText: "Creation",
      labelAproxDate: "Estimated border delivery",
      labelTotalOrder: "Order total",
      labelManejoWarehouse: "Warehouse management",
      labelCostSend: "Shipping breakdown",
      labelCostSendMexico: "Freight cost (Mexico)",
      labelCostSendEua: "Freight cost (EE.UU)",
      labelCostInOut: "In-out cost",
      labelFactureText: "Invoice",
      labelDownloadFile: "Download file",
      labelComprobantPay: "Proof of payment",
      labelUpload: "Click to add your <br /> proof of payment",
      labelProducts: "Items",
      labelBoxSolicit: "Requested boxes",
      labelSubtotalInitial: "Initial subtotal",
      labelBoxAdjust: "Fitted boxes",
      labelSubtotalFinal: "Final subtotal",
      labelWarehouse: "Warehouse",
      labelWarehouseSend: "Shipping warehouse",
      labelWarehouseEnt: "Delivery warehouse",
      labelPaymentMethod: "Payment method",
      labelSeg: "Follow-up",
    },
  },
  sp: {
    orderdetail: {
      labelsInOut: {
        name: "Nombre",
        numPallets: "Número de pallets",
        pricePallet: "Precio por pallet",
        subtotal: "Subtotal",
      },
      buttonShowDispute: "Ver disputa",
      sectionArticle: {
        text1: "Artículo",
        text2: "Cantidad solicitada",
        text3: "Precio por unidad",
        text4: "Subtotal final",
      },

      aHeaderArticle: [
        {
          text: "Artículo",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "sProductName",
        },
        {
          text: "Cantidad solicitada",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "dDeliveredQuantity",
        },
        {
          text: "Precio por unidad",
          align: "start",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "dDeliveredUnitPrice",
        },
        {
          text: "Subtotal final",
          align: "end",
          class:"text-order-label mon-medium",
          sortable: false,
          value: "dFinalPrice",
        },
      ],
      sectionFollow: {
        textTitle: "Seguimiento",
        text1: "Pedido confirmado",
        text2: "En preparación",
        text3: "En tránsito",
        text4: "En proceso de cruce fronterizo",
        text5: "Cruce exitoso",
        text6: "Almacén frontera",
        text7: "Aceptado en almacén frontera",
        text8: "Transferido",
        text9: "En ruta de entrega",
        text10: "Entregado",
        text11: "Finalizado",
      },
      buttonStartDispute: "Comenzar disputa",
      buttonFinalizeOrder: "Finalizar pedido",
      buttonReturn: "Regresar",
      labelCreationText: "Creación",
      labelAproxDate: "Fecha estimada de entrega en frontera",
      labelTotalOrder: "Total de la orden",
      labelManejoWarehouse: "Manejo en almacén",
      labelCostSend: "Desglose de costos",
      labelCostSendMexico: "Costo de flete (México)",
      labelCostSendEua: "Costo de flete EE.UU",
      labelCostInOut: "Costo in-out",
      labelFactureText: "Factura",
      labelDownloadFile: "Descargar archivo",
      labelComprobantPay: "Comprobante de pago",
      labelUpload: "Da click para agregar tu <br /> comprobante de pago",
      labelProducts: "Artículos",
      labelBoxSolicit: "Cajas solicitadas",
      labelSubtotalInitial: "Subtotal inicial",
      labelBoxAdjust: "Cajas ajustadas",
      labelSubtotalFinal: "Subtotal final",
      labelWarehouse: "Almacén",
      labelWarehouseSend: "Almacén de envío",
      labelWarehouseEnt: "Almacén de entrega",
      labelPaymentMethod: "Método de pago",
      labelSeg: "Seguimiento",
    },
  },
};
export default texts;
