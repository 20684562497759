var texts = {
  en: {
    shoppingcart: {
      seguir: "Click here to continue shopping",
      textPer: "per box",
      textTotal: "Total",
      textTitle: "Shopping cart",
      textKeepShoppingFromThisSupplier: "shop more from this supplier",
      textHeaderProduct: "Item",
      textHeaderUnitPrice: "Unit price",
      textHeaderWarehouse: "Warehouse",
      textHeaderQuantity: "Quantity",
      textHeaderTotal: "Total",
      textSubtotal: "Subtotal",
      textTerms: "*Shipping costs will be calculated at checkout*",
      textPalletsOutOf: "pallets available from",
      textPalletsTotals: " total",
      textButtonPayment: "Proceed to checkout",
      buttonRemove: "Remove",
      delete: {
        textTitle: "item",
      },
      textEmptyContent: "Sorry, there are no items in the shopping cart yet.",
      textButtonModify: "Modify",
      emptyWarehouse:
        "To proceed to checkout. You need to register at least one warehouse.",
      textAmountGreaterThan: "Insert an amount greater than",
      textAmountLessThan: "Insert an amount less than",
      textNoProvider: "There are no articles in the shopping cart",
      textProblemCart:
        "One of the items in the shopping cart has been modified, please review your shopping cart.",

      // NameProvider: 'SuperMarket',
    },
  },
  sp: {
    shoppingcart: {
      seguir: "Da click aquí para seguir comprando",
      textPer: "por caja",
      textTotal: "Total",
      textTitle: "Carrito de compras",
      textKeepShoppingFromThisSupplier: "shop more from this supplier",
      textHeaderProduct: "Artículo",
      textHeaderUnitPrice: "Precio unitario",
      textHeaderWarehouse: "Almacén",
      textHeaderQuantity: "Cantidad",
      textHeaderTotal: "Total",
      textSubtotal: "Subtotal",
      textTerms: "*Los costos de envío se calcularán en el checkout*",
      textPalletsOutOf: " tarimas disponibles de",
      textPalletsTotals: " totales",
      textButtonPayment: "Proceder al checkout",
      buttonRemove: "Remover",
      textKeepShoppingFromThisSupplier: "comprar más de este proveedor",

      delete: {
        textTitle: "artículo",
      },
      textEmptyContent:
        "Lo sentimos, todavía no hay elementos dentro <br/> del carrito de compras.",
      textButtonModify: "Modificar",
      emptyWarehouse:
        "Para proceder al checkout. Debe registrar al menos un almacén.",
      textAmountGreaterThan: "Ingresa una cantidad mayor a ",
      textAmountLessThan: "Inserta una cantidad menor a ",
      textNoProvider: "No hay articulos en el carrito",
      textProblemCart:
        "Uno de los artículos del carrito de compras ha sido modificado, favor de revisar de nuevo su pedido desde el carrito de compras.",
      // NameProvider: 'SuperMarker',
    },
  },
};

export default texts;
