<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
          sm="6"
        >
          <div>
            <div class="display-flex align-items-center">
              <p class="txt-title mb-0 mr-1 mon-bold">{{ texts.itemSold.titleSection }}</p>
              <img
                width="25px"
                height="25px"
                src="@/assets/images/fuego.png"
                alt=""
              >
            </div>
          </div>
        </v-col>
        <v-col
          class="pb-0"
          cols="12"
          sm="6"
        >
          <div class="align-content">
            <v-btn
              :to="{ name: 'Article', params: { idCategory: 'all', idSubcategory: 'b87bbcf26818fb2e6262fffeab402f03' }}"
              class="button-show-more pa-0 mon-medium"
              text
            >
              {{ texts.itemSold.viewItems }}
              <v-icon size="16px">
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <!-- <v-btn
              class="carousel-button-prev ml-5"
              @click="$refs.buttonPrev.click()"
            >
              <v-icon
                size="18px"
                color="#73777E"
              >
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-btn
              class="carousel-button-next ml-2"
              @click="$refs.buttonNext.click()"
            >
              <v-icon
                size="18px"
                color="#73777E"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loaderSkeleton">
          <home-item-sold-skeleton-layout />
        </v-col>
        <v-col
          v-else
          cols="12"
          sm="12"
        >
          <div>
            <v-container
              class="px-0"
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <div
                    v-if="aPopularArticles.length > 0"
                    class="container-carousel-articles"
                  >
                    <div
                      v-for="(item, index) in aPopularArticles"
                      :key="index"
                      class="container-card-articles"
                    >
                      <div class="square">
                        <div class="content-image">
                          <img
                            v-if="item.aImages.length > 0"
                            :src="item.aImages[0].oImages.md"
                            alt=""
                          >
                          <img
                            v-else
                            src="@/assets/images/placeholder-image.jpeg"
                            alt=""
                          >
                        </div>
                      </div>
                      <div class="mt-5">
                        <p class="text-article mon-bold">{{ item.sName }}</p>
                        <p
                          v-if="item.sVendorEnterpriseId !== null"
                          class="text-provider mon-bold"
                        >
                          <v-icon
                            size="15px"
                            color="#9AA6B1"
                          >
                            mdi-account
                          </v-icon>
                          {{ item.sVendorEnterpriseName }}
                        </p>
                        <p class="text-location mon-bold">
                          <v-icon
                            size="15px"
                            color="#9AA6B1"
                          >
                            mdi-map-marker
                          </v-icon>
                          {{ item.sLocationWarehouse }}
                        </p>
                        <div class="display-flex align-items-center">
                          <v-rating
                            :value="item.dRating"
                            length="5"
                            color="#FFC92A"
                            background-color="#C6C6C8"
                            dense
                            small
                            readonly
                          ></v-rating>
                          <p
                            :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}"
                            class="text-calif-num mon-bold ml-1 mb-n1"
                          >
                            {{ 
                                (item.dRating > 5)
                                ? 5
                                : item.dRating
                              }}
                          </p>
                        </div>
                        <p class="txt-price mt-2 mon-bold">${{ formatMoney(item.dUnitPrice) }} USD</p>
                        <div class="display-flex align-items-center">
                          <div
                            v-if="$store.state.sToken"
                            class="width-50 display-flex align-items-center justify-content-flex-start"
                          >
                            <v-btn
                              @click="addShoppingCart(item.sProductId, item.dMinAmountAllowed)"
                              class="button-add mon-bold"
                              elevation="0"
                            >
                              {{ texts.itemSold.buttonAddItem }}
                              <v-icon
                                class="ml-1"
                                size="15px"
                                color="#283C4D"
                              >
                                mdi-cart-outline
                              </v-icon>
                            </v-btn>
                          </div>
                          <div
                            :class="($store.state.sToken)? 'width-50 ':'width-100'"
                            class="display-flex align-items-center "
                          >
                            <v-btn
                              :to="{ name: 'ViewArticle', params: { id: item.sProductId }}"
                              class="button-view mon-bold"
                              elevation="0"
                            >
                              {{ texts.itemSold.buttonViewItem }}
                              <v-icon
                                class="ml-1"
                                size="15px"
                                color="#283C4D"
                              >
                                mdi-arrow-right
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <carousel-component
                      :dots="false"
                      :nav="false"
                      :items="1"
                      style="position: relative"
                      :responsive="responsiveCarousel"
                    >

                      // TODO
                      <template slot="prev">
                        <span ref="buttonPrev"></span>
                      </template>
                      <template slot="next">
                        <span ref="buttonNext"></span>
                      </template>
                    </carousel-component> -->
                  </div>
                  <div v-else>
                    <p class="text-empty-home mon-regular">{{ texts.itemSold.emptyText }}</p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeItemSoldLayout",
  data() {
    return {
      //VARIABLES
      responsiveCarousel: {
        0: { items: 1, nav: false },
        425: { items: 2, nav: false },
        600: { items: 3, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1636: { items: 6, nav: false },
        1904: { items: 6, nav: false },
      },
      loaderSkeleton: true,
      aPopularArticles: [],
    };
  },
  beforeMount() {
    // this.$nextTick((e) => {
    this.getPopularArticles();
    // });
  },
  methods: {
    addShoppingCart: function (sProductId, min) {
      if (this.$store.state.isLogged) {
        const payload = {
          sProductId: sProductId,
          dQuantity: min,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
          payload,
          config
        )
          .then((response) => {
            this.mixSuccess(response.data.message);

            this.$store.commit("setFadeNotificationCart", true);
            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.mixError(this.texts.article.textNoSession);
      }
    },
    getPopularArticles: function () {
      this.loaderSkeleton = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
        params: {
          aImagesSizes: "md",
          iItemsPerPage: 10,
          iPageNumber: 1,
          sRating: "desc",
        },
      })
        .then((response) => {
          this.loaderSkeleton = false;

          let item = response.data.results;
          this.aPopularArticles = item;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney(money) {
      //return number in format money
      return FORMAT_MONEY(money);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getPopularArticles();
      }
    },
  },
};
</script>

<style scoped>
.txt-article {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
}

.text-location {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
}

.txt-title-card {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0;
}

.txt-points {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0;
}

.txt-catalog {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 5px;
}

.txt-reviews {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0;
}

.txt-price {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.button-add {
  width: 95%;
  height: 35px !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  background-color: #ffffff !important;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: capitalize;
}

.button-view {
  width: 100%;
  height: 35px !important;
  background: transparent linear-gradient(250deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: capitalize;
}

.width-50 {
  width: 50%;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.container-card-articles {
  min-width: 320px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
  padding: 20px;
  margin-right: 15px;
}

.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

/* estilos viejos */

.carousel-button-next,
.carousel-button-prev {
  min-width: 30px !important;
  height: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  padding: 0px !important;
}

.txt-market {
  text-align: left;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 18px;
  margin-bottom: 5px;
}

.txt-calification {
  text-align: left;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  font-size: 12px;
  margin-bottom: 0;
}

.txt-response {
  text-align: left;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.txt-response-time {
  text-align: left;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.txt-orders {
  text-align: left;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.txt-orders-number {
  text-align: left;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.txt-name {
  text-align: center;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 14px;
  margin-top: 5px;
}

.content-card-article {
  width: 97%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 20px;
}

.fixed-width {
  width: 125px;
}

.resize-width {
  width: calc(100% - 125px);
}

.button-show-more::before {
  background-color: transparent !important;
}

.align-content {
  text-align: right;
}

.button-show-more {
  color: #0071ff;
  text-transform: initial;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: underline;
}

.txt-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.container-carousel-articles {
  display: flex;
  overflow: auto;
  width: 100%;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}

@media (max-width: 600px) {
  .align-content {
    text-align: left;
  }
}
</style>